import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Button,
  Dropdown,
  SemanticCOLORS,
  SemanticICONS,
  Input,
  Grid,
  Select,
  Container,
  Segment,
  Label,
} from "semantic-ui-react";
import * as XLSX from "xlsx";
import { parseISO } from "date-fns";
import { debounce } from "lodash";
import { DataTableList } from "./interfaces/TableData";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default observer(function DataTable<T extends object>({
  data,
  columns,
  defaultPageSize = 10,
  defaultOrderByColumn = "",
  onFilter,
  onClick,
  actionButtons,
  exportAgentMethod,
  clickUrl,
  totalColumn,
  disableRowClick = false,
}: Props<T>) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(data.currentPage);
  const [pageSize, setPageSize] = useState(data.pageSize);
  const [orderBy, setOrderBy] = useState(defaultOrderByColumn);
  const [sortDirection, setSortDirection] = useState("asc");
  const [columnFilters, setColumnFilters] = useState<{ [key: string]: string }>(
    {}
  );

  const flattenData = <T extends object>(data: T[]): any[] => {
    return data.map((item) => {
      const flattenedItem: any = {};
      for (const key in item) {
        if (typeof item[key] === "object" && item[key] !== null) {
          for (const nestedKey in item[key]) {
            flattenedItem[`${key}.${nestedKey}`] = item[key][nestedKey];
          }
        } else {
          flattenedItem[key] = item[key];
        }
      }
      return flattenedItem;
    });
  };
  const flattenedData = flattenData(Array.from(data.list.values()));

  const totalAmount = totalColumn
    ? flattenedData.reduce(
        (total, item) => total + parseFloat(item[totalColumn] || 0),
        0
      )
    : null;

  const formatValue = (value: any, column?: DataTableColumn<T>) => {
    const date = parseISO(value);

    if (column?.dataType === DataType.DATE && date instanceof Date) {
      const formattedDate = date?.toLocaleDateString();
      return date instanceof Date && !isNaN(date.getTime())
        ? formattedDate
        : "";
    }

    if (column?.dataType === DataType.BOOLEAN) {
      return value ? <i className="check square icon"></i> : "";
    }

    if (column?.dataType === DataType.NUMBER && column?.formatPrice) {
      return value?.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    if (column?.dataType === DataType.SELECT) {
      const item = column?.options?.filter((x) => x.value === value)?.[0];
      return item?.text || value;
    }

    return value;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const debouncedFilter = useCallback(
    debounce((filters: any) => {
      if (onFilter) {
        onFilter({
          filters,
          page: currentPage,
          pageSize,
          orderBy,
          sortDirection,
        });
      }
    }, 300),
    []
  );

  const handleFilterChange = (
    columnKey: string,
    value: any,
    dataType: DataType = DataType.TEXT
  ) => {
    let updatedFilters = {};

    if (value === null || value === "") {
      const { [columnKey]: _, ...updatedFilters } = columnFilters;
      setColumnFilters(updatedFilters);
    } else {
      const data = dataType === DataType.NUMBER ? +value : value;
      updatedFilters = { ...columnFilters, [columnKey]: data };
      setColumnFilters(updatedFilters);
    }

    debouncedFilter(updatedFilters);
  };

  const handlePageSizeChange = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: any
  ) => {
    const newSize =
      parseInt(data.value as string, 10) || defaultPageSize || 100;
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleOrderByChange = (
    _event: React.SyntheticEvent<HTMLElement>,
    columnKey: string
  ) => {
    const newSortDirection =
      orderBy === columnKey && sortDirection === "asc" ? "desc" : "asc";

    setOrderBy(columnKey);
    setSortDirection(newSortDirection);
  };

  const exportToExcel = async () => {
    if (exportAgentMethod == null) {
      return;
    }

    try {
      const response = await exportAgentMethod({
        filters: columnFilters,
        orderBy,
        sortDirection,
      });
      const worksheet = XLSX.utils.json_to_sheet(response);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "data.xlsx";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const pageSizeOptions = [10, 20, 50, 100];
  const totalPages = Math.ceil(data.totalRecords / pageSize);

  useEffect(() => {
    onFilter &&
      onFilter({
        filters: columnFilters,
        page: currentPage,
        sortDirection,
        pageSize,
        orderBy,
      });
  }, [currentPage, pageSize, orderBy, sortDirection]);

  return (
    <Container
      fluid
      style={{ maxWidth: "100%", marginTop: "5px", userSelect: "text" }}
    >
      <Table selectable striped className="ui compact table data-table">
        <Table.Header>
          <Table.Row>
            {columns.map((column) => (
              <Table.HeaderCell
                key={column.key}
                style={{
                  verticalAlign: "top",
                  cursor: "pointer",
                  width: column.width || "auto",
                }}
              >
                <div onClick={(e: any) => handleOrderByChange(e, column.key)}>
                  {column.header}
                  {orderBy === column.key && (
                    <i
                      className={`icon ${
                        sortDirection === "asc" ? "caret up" : "caret down"
                      }`}
                    />
                  )}
                </div>
                {column.filterable &&
                  getFilterInput<T>(column, columnFilters, handleFilterChange)}
              </Table.HeaderCell>
            ))}
            {actionButtons?.length && <Table.HeaderCell></Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {flattenedData?.length ? (
            <>
              {flattenedData.map((item: any) => (
                <Table.Row
                  key={item.id}
                  onClick={(e: any) => {
                    if (disableRowClick) return;

                    const selection = window.getSelection();
                    if (!selection || selection.toString() !== "") {
                      return;
                    }
                    onClick && onClick(item);
                  }}
                >
                  {columns.map((column) => (
                    <Table.Cell
                      style={{
                        display: "table-cell",
                        userSelect: "text",
                        position: "relative",
                        color: "inherit",
                        verticalAlign: "middle",
                        height: "50px",
                      }}
                      key={column.key}
                      as={disableRowClick ? "div" : Link}
                      to={
                        disableRowClick
                          ? undefined
                          : clickUrl
                          ? clickUrl(item)
                          : "#"
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          verticalAlign: "middle",
                          padding: "5px",
                        }}
                      >
                        {formatValue(
                          column.render
                            ? column.render(item)
                            : item[column.key] ||
                                item[column.additionalKey as any],
                          column
                        ) || "-"}
                      </div>
                    </Table.Cell>
                  ))}
                  {actionButtons?.length && (
                    <Table.Cell>
                      {actionButtons.map((button) => (
                        <Button
                          icon={button.icon}
                          key={button.icon}
                          onClick={(e) => button.onClick(e, item)}
                          color={button.color}
                        />
                      ))}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
              {totalColumn && (
                <>
                  <Table.Row>
                    {columns.map((column, index) => (
                      <>
                        <Table.HeaderCell key={index}>
                          {totalColumn === column.key && (
                            <Label color="green" size="large">
                              {t("general.total")}: {totalAmount}
                            </Label>
                          )}
                        </Table.HeaderCell>
                      </>
                    ))}
                    {actionButtons?.length && (
                      <Table.HeaderCell></Table.HeaderCell>
                    )}
                  </Table.Row>
                </>
              )}
            </>
          ) : (
            <Table.Row>
              <Table.Cell>
                <Segment textAlign="center" basic>
                  {t("dataTable.noData")}
                </Segment>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

        <Table.Footer className="full-width">
          <Table.Row>
            <Table.HeaderCell
              colSpan={columns.length + (actionButtons?.length ? 1 : 0)}
            >
              <Grid columns={2} verticalAlign="middle">
                <Grid.Column width={4}>
                  <Dropdown
                    selection
                    compact
                    options={pageSizeOptions.map((size) => ({
                      key: size,
                      text: size.toString(),
                      value: size,
                    }))}
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {t("dataTable.rowsPerPage", { count: data.totalRecords })}
                  </span>
                </Grid.Column>
                <Grid.Column width={12} textAlign="right">
                  <Pagination
                    totalPages={totalPages}
                    activePage={currentPage}
                    onPageChange={(_, { activePage }) =>
                      handlePageChange(activePage as number)
                    }
                    ellipsisItem={{ content: "..." }}
                    siblingRange={1}
                    boundaryRange={1}
                  />
                  {exportAgentMethod != undefined && (
                    <Button
                      style={{ marginTop: "3px" }}
                      onClick={exportToExcel}
                      floated="right"
                      positive
                    >
                      <i className="file excel outline icon"></i>
                      {t("Excel")}
                    </Button>
                  )}
                </Grid.Column>
              </Grid>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
});

function getFilterInput<T extends object>(
  column: DataTableColumn<T>,
  columnFilters: { [key: string]: string },
  handleFilterChange: (
    columnKey: string,
    value: any,
    dataType?: DataType
  ) => void
): React.ReactNode {
  if (column.dataType === DataType.SELECT) {
    return (
      <Container fluid>
        <Select
          fluid
          clearable
          options={column.options || []}
          value={columnFilters[column.key] || column.defaultValue || ""}
          onChange={(e, d) =>
            handleFilterChange(column.key, d.value, column.dataType)
          }
          placeholder={`${column.header}`}
        />
      </Container>
    );
  }

  if (column.dataType === DataType.DATE) {
    return (
      <Grid columns={2} style={{ maxWidth: "280px" }}>
        <Grid.Column>
          <Input
            size="small"
            type="date"
            value={columnFilters[`${column.key}Start`] || ""}
            onChange={(e) =>
              handleFilterChange(
                `${column.key}Start`,
                e.target.value,
                column.dataType
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <Input
            size="small"
            type="date"
            value={columnFilters[`${column.key}End`] || ""}
            onChange={(e) =>
              handleFilterChange(
                `${column.key}End`,
                e.target.value,
                column.dataType
              )
            }
          />
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <Container fluid>
      <Input
        size="small"
        type={column.dataType || DataType.TEXT}
        placeholder={`${column.header}`}
        value={
          columnFilters[column.key] !== undefined
            ? columnFilters[column.key]
            : ""
        }
        onChange={(e) =>
          handleFilterChange(column.key, e.target.value, column.dataType)
        }
        fluid
      />
    </Container>
  );
}

export enum DataType {
  NUMBER = "number",
  TEXT = "text",
  DATE = "date",
  BOOLEAN = "boolean",
  SELECT = "select",
}

type DataTableColumn<T> = {
  key: string;
  text?: string;
  additionalKey?: string;
  header: string;
  render?: (item: T) => React.ReactNode;
  dataType?: DataType;
  filterable?: boolean;
  options?: any[];
  width?: string;
  defaultValue?: any;
  formatPrice?: boolean;
};

type DataTableActionButtons<T> = {
  icon: SemanticICONS;
  color: SemanticCOLORS;
  onClick: (e: React.MouseEvent, item: T) => void;
};

interface Props<T> {
  data: DataTableList<T>;
  columns: DataTableColumn<T>[];
  defaultPageSize?: number;
  defaultOrderByColumn?: string;
  onFilter?: (filterProps: FilterProps) => void;
  onClick?: (item: T) => void;
  actionButtons?: DataTableActionButtons<T>[];
  exportAgentMethod?: (filters?: FilterProps) => any;
  clickUrl?: (item: T) => string;
  totalColumn?: string;
  disableRowClick?: boolean;
}

export type FilterProps = {
  filters?: any;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sortDirection?: string;
};
