import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import DataTable, { FilterProps } from "../../app/common/DataTable";
import { router } from "../../app/router/Routes";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import { Button, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { Participant } from "../../app/models/participant";
import { useParams } from "react-router-dom";
import ParticipantAutocomplete from "./ParticipantAutoComplete";
import { useTranslation } from "react-i18next";

export default observer(function ParticipantsTable() {
  const { participantStore, modalStore } = useStore();
  const { id } = useParams();
  const { t } = useTranslation();

  const columns = [
    {
      key: "member.firstName",
      additionalKey: "familyMember.firstName",
      header: t("members.firstName"),
    },
    {
      key: "member.lastName",
      additionalKey: "familyMember.lastName",
      header: t("members.lastName"),
    },
    {
      key: "member.email",
      additionalKey: "familyMember.email",
      header: t("members.email"),
    },
    {
      key: "member.phone",
      additionalKey: "familyMember.phone",
      header: t("members.phone"),
    },
  ];
  const handleDelete = async (id: string) => {
    await participantStore.deleteParticipant(id);
    modalStore.closeModal();
  };
  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: Participant) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  return (
    <>
      <Button
        onClick={() =>
          modalStore.openModal(
            <ParticipantAutocomplete activityId={id || ""} />,
            "small"
          )
        }
        positive
      >
        {t("participants.addParticipant")}
      </Button>
      <DataTable
        columns={columns}
        data={participantStore.tableData}
        clickUrl={(item: any) =>
          `/update-member/${item.memberId ?? item["familyMember.memberId"]}`
        }
        actionButtons={actionButtons}
        onFilter={(filterProps: FilterProps) =>
          participantStore.loadParticipants(id!, filterProps)
        }
      />
    </>
  );
});
