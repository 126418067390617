import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import DataTable, { DataType } from "../../app/common/DataTable";
import { router } from "../../app/router/Routes";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import { Header, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { Member } from "../../app/models/member";
import { useEffect } from "react";

export default observer(function PendingMembersTable() {
  const { t } = useTranslation();
  const { memberStore, modalStore } = useStore();
  const columns = [
    {
      key: "memberNumber",
      header: t("members.memberNumber"),
      dataType: DataType.NUMBER,
      filterable: true,
    },
    { key: "firstName", header: t("members.firstName"), filterable: true },
    { key: "lastName", header: t("members.lastName"), filterable: true },
    { key: "email", header: t("members.email"), filterable: true },
    { key: "phone", header: t("members.phone"), filterable: true },
    { key: "profession", header: t("members.profession"), filterable: true },
  ];

  const handleDelete = async (id: string) => {
    await memberStore.deleteMember(id);
    modalStore.closeModal();
  };

  const handleApproveMember = async (id: string) => {
    try {
      await memberStore.approveMember(id);
      modalStore.closeModal();
    } catch (error) {
      modalStore.closeModal();
    }
  };

  const actionButtons = [
    {
      icon: "check" as SemanticICONS,
      color: "green" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: Member) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            text={t("members.approveConfirmation")}
            onYes={async () => handleApproveMember(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: Member) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            text={t("members.deleteConfirmation")}
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  useEffect(() => {
    memberStore.loadPendingMembersTable({});
  }, []);

  return (
    <>
      <Header as="h2" content={t("general.pendingMembers")} />
      <DataTable
        columns={columns}
        data={memberStore.tableData}
        clickUrl={(item) => `/update-member/${item.id}`}
        actionButtons={actionButtons}
        onFilter={memberStore.loadPendingMembersTable}
        defaultOrderByColumn="lastName"
      />
    </>
  );
});
