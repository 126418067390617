import React, { useState } from "react";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { router } from "../../app/router/Routes";

export default observer(function UploadMembers() {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const { memberStore } = useStore();
  const { loading } = memberStore;

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage(t("uploadMembers.chooseFileLabel"));
      return;
    }

    const formData = new FormData();
    formData.append("xlsxFile", file);

    try {
      await memberStore.uploadMembersXlsxData(formData);
      setMessage(t("uploadMembers.successMessage"));
      router.navigate("/members");
    } catch (error) {
      setMessage(t("uploadMembers.errorMessage"));
    }
  };

  return (
    <Segment>
      <h2>{t("uploadMembers.title")}</h2>

      {loading && (
        <LoadingComponent content={t("uploadMembers.loadingMessage")} />
      )}

      {!loading && (
        <Form>
          <Form.Field>
            <label>{t("uploadMembers.chooseFileLabel")}</label>
            <input type="file" accept=".xlsx" onChange={handleFileChange} />
          </Form.Field>
          <Button primary onClick={handleUpload}>
            {t("uploadMembers.uploadButton")}
          </Button>
        </Form>
      )}

      {message && <Message>{message}</Message>}
    </Segment>
  );
});
