import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Tenant } from "../models/tenant";
import { DataTableList } from "../common/interfaces/TableData";


export default class TenantStore {
    tableData = new DataTableList<Tenant>();
    selectedTenant?: Tenant = undefined;
    loadingInitial = false;
    editMode = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        return params;
    }

    loadTenants = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.Tenants.list(this.axiosParams);
            this.setTenants(result);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadTenant = async (id: string) => {
        let tenant = this.getTenant(id);
        if (tenant) {
            this.selectedTenant = tenant;
            return tenant;
        }
        else {
            this.setLoadingInitial(true);
            try {
                tenant = await agent.Tenants.details(id);
                this.setTenant(tenant);
                runInAction(() => this.selectedTenant = tenant);
                this.setLoadingInitial(false);
                return tenant;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setTenants = (tenants: Tenant[]) => {
        this.tableData.list = tenants.reduce((acc: Map<string, Tenant>, item: Tenant) => {
            acc.set(item.id!, item);
            return acc;
        }, new Map<string, Tenant>());
    }

    private setTenant = (tenant: Tenant) => {
        this.tableData.list.set(tenant?.id!, tenant);
    }

    private getTenant = (id: string) => {
        return this.tableData.list.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTenant = async (tenant: Tenant) => {
        try {
            await agent.Tenants.create(tenant);
            this.setTenant(tenant);
            runInAction(() => this.selectedTenant = tenant);
        } catch (error) {
            console.log(error);
        }
    }

    updateTenant = async (tenant: Tenant) => {
        try {
            await agent.Tenants.update(tenant);
            runInAction(() => {
                if (tenant.id) {
                    const existingTenant = this.getTenant(tenant.id);
                    if (existingTenant) {
                        Object.assign(existingTenant, tenant);
                        this.selectedTenant = existingTenant;
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    deleteTenant = async (id: string) => {
        this.loading = true;
        try {
            await agent.Tenants.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    clearSelectedTenant = () => {
        this.selectedTenant = undefined;
    }
}