export class DataTableList<T> {
    list: Map<string, T>;
    filters?: {};
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    orderBy: string;

    constructor() {
        this.list = new Map<string, T>();
        this.totalRecords = 0;
        this.currentPage = 1;
        this.pageSize = 10;
        this.orderBy = "";
    }
}

export interface DataTableFilterProps { filters?: any, page?: number, pageSize?: number, orderBy?: string; }