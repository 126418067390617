import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Segment,
  Header,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";
import axios from "axios";
import DataTable from "../../app/common/DataTable";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import { Tenant } from "../../app/models/tenant";

export default observer(function CreateTenantForm() {
  const [formData, setFormData] = useState({
    name: "",
    contactPhone: "",
    contactName: "",
    contactEmail: "",
  });
  const { tenantStore, modalStore } = useStore();
  const { loadTenants } = tenantStore;
  const columns = [
    { key: "name", header: "Ime džemata" },
    { key: "contactPhone", header: "Kontakt telefon" },
    { key: "contactEmail", header: "Kontakt email" },
  ];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await axios.post("/tenants", formData);
      loadTenants();
      resetFormData();
      console.log("Tenant created successfully");
    } catch (error) {
      console.error("Error creating tenant:", error);
    }
  };

  const resetFormData = () => {
    setFormData({
      name: "",
      contactPhone: "",
      contactName: "",
      contactEmail: "",
    });
  };

  const handleDelete = async (id: string) => {
    await tenantStore.deleteTenant(id);
    modalStore.closeModal();
  };

  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: Tenant) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  useEffect(() => {
    loadTenants();
  }, []);

  return (
    <>
      <Segment>
        <Header as="h2" content="Dodaj Džemat" />
        <Form>
          <Form.Input
            label="Ime džemata"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <Form.Input
            label="Kontakt ime"
            name="contactName"
            value={formData.contactName}
            onChange={handleInputChange}
          />
          <Form.Input
            label="Kontakt telefon"
            name="contactPhone"
            value={formData.contactPhone}
            onChange={handleInputChange}
          />
          <Form.Input
            label="Kontakt e-mail"
            name="contactEmail"
            value={formData.contactEmail}
            onChange={handleInputChange}
          />
          <Button primary onClick={handleSubmit}>
            Kreiraj džemat
          </Button>
        </Form>
      </Segment>

      <Segment>
        <DataTable
          columns={columns}
          data={tenantStore.tableData}
          //   clickUrl={(item) => `/update-member/${item.id}`}
          actionButtons={actionButtons}
        />
      </Segment>
    </>
  );
});
