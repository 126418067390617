import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import ActivityTable from "./ActivityTable";
import { Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function ActivityList() {
  const { activityStore } = useStore();
  const { loadActivities } = activityStore;
  const { t } = useTranslation();

  useEffect(() => {
    loadActivities({});
  }, [loadActivities]);

  return (
    <>
      <Header as="h2" content={t("navbar.activities")} />
      <ActivityTable />
    </>
  );
}
