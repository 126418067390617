import { useTranslation } from "react-i18next";

export enum SubscriptionType {
    Monthly = 1,
    Yearly = 2
}

export const SubscriptionTypeStrings: {
    [key in SubscriptionType]: string;
} = {
    [SubscriptionType.Monthly]: 'monthly',
    [SubscriptionType.Yearly]: 'yearly',
};

export const useSubscriptionTypeOptions = () => {
    const { t } = useTranslation();

    return Object.keys(SubscriptionType)
        .filter((key: any) => !isNaN(Number(SubscriptionType[key])))
        .map((key: any) => ({
            key: key,
            value: SubscriptionType[key],
            text: t(`general.${(SubscriptionTypeStrings as any)[SubscriptionType[key]]}`),
        }));
};