import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { UserRole } from "../../app/common/enums/userRole";
import CreateUserForm from "../users/CreateUserForm";
import Dashboard from "./Dashboard";

export default observer(function HomePage() {
  const { memberStore, userStore } = useStore();
  const { loadMembers } = memberStore;

  useEffect(() => {
    if (userStore.userRole === UserRole.ADMIN) {
      loadMembers({ orderBy: "lastName" });
    }
  }, [loadMembers]);

  return (
    <>
      {userStore.userRole === UserRole.ADMIN ? (
        <Dashboard />
      ) : (
        <CreateUserForm />
      )}
    </>
  );
});
