import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDateInput from "../../app/common/form/CustomDateInput";
import {
  Button,
  Divider,
  Header,
  Label,
  Segment,
  Table,
} from "semantic-ui-react";
import {
  NewMember,
  getDefaultNewMemberValues,
} from "../../app/common/interfaces/NewMember";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import CustomRadioInput from "../../app/common/form/CustomRadioInput";
import { NewFamilyMember } from "../../app/common/interfaces/NewFamilyMember";
import {
  PaymentMethod,
  usePaymentMethodOptions,
} from "../../app/common/enums/paymentType";
import CustomCheckbox from "../../app/common/form/CustomCheckBox";
import CustomSelectInput from "../../app/common/form/CustomSelectInput";
import { useStore } from "../../app/stores/store";
import { v4 as uuid } from "uuid";
import { useSubscriptionTypeOptions } from "../../app/common/enums/subscriptionType";
import { useRelationShipTypeOptions } from "../../app/common/enums/relationType";
import { useSalutationTypeOptions } from "../../app/common/enums/salutationType";

export default function RegisterMemberForm() {
  const { t } = useTranslation();
  const [member, setMember] = useState<NewMember>(getDefaultNewMemberValues());
  const [memberRegistered, setMemberRegistered] = useState(false);
  const { memberStore } = useStore();
  const subscriptionTypeOptions = useSubscriptionTypeOptions();
  const paymentMethodOptions = usePaymentMethodOptions();
  const relationShipTypeOptions = useRelationShipTypeOptions();
  const salutationTypeOptions = useSalutationTypeOptions();
  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("registrationForm.firstNameRequired")),
    lastName: Yup.string().required(t("registrationForm.lastNameRequired")),
    country: Yup.string().required(t("registrationForm.countryRequired")),
    city: Yup.string().required(t("registrationForm.cityRequired")),
    zipCode: Yup.string().required(t("registrationForm.zipCodeRequired")),
    memberShipFee: Yup.number().required(
      t("registrationForm.membershipFeeRequired")
    ),
    paymentMethod: Yup.string().required(
      t("registrationForm.paymentMethodRequired")
    ),
    email: Yup.string().required(t("registrationForm.emailRequired")),
    bankName: Yup.string().when("paymentMethod", {
      is: PaymentMethod.Account,
      then(schema) {
        return schema.required(t("registrationForm.bankNameRequired"));
      },
    }),
    iban: Yup.string().when("paymentMethod", {
      is: PaymentMethod.Account,
      then(schema) {
        return schema.required(t("registrationForm.ibanRequired"));
      },
    }),
    bic: Yup.string().when("paymentMethod", {
      is: PaymentMethod.Account,
      then(schema) {
        return schema.required(t("registrationForm.bicRequired"));
      },
    }),
    agreeTerms: Yup.boolean().oneOf(
      [true],
      t("registrationForm.agreeTermsRequired")
    ),
  });

  const getCheckboxText = (values: any) => {
    if (values.paymentMethod === PaymentMethod.Account) {
      return t("registrationForm.checkboxTextAccount");
    } else {
      return t("registrationForm.checkboxTextGeneral");
    }
  };

  const handleFormSubmit = async (values: NewMember) => {
    await memberStore.registerMember(values);
    setMemberRegistered(true);
    window.scrollTo(0, 0);
  };

  if (memberRegistered) {
    return (
      <Segment clearing>
        <div className="ui positive message">
          <div className="header">
            {t("registrationForm.successfullyRegistered")}
          </div>
          <p>
            {t("registrationForm.successfulRegistrationMessage")}
            <p>
              {t("registrationForm.visitWebsiteMessage")}
              <b>
                <a href="https://dzemat-koeln.chayns.site">
                  {t("registrationForm.websiteLinkText")}
                </a>
              </b>
            </p>
          </p>
        </div>
      </Segment>
    );
  }

  return (
    <Segment clearing>
      <div className="MembershipApplication">
        <h1>{t("registrationForm.membershipApplication")}</h1>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{ ...member, agreeTerms: false }}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, values }) => (
            <Form
              className="ui form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Header
                content={t("registrationForm.personalInformation")}
                sub
                color="teal"
              />
              <div className="four fields">
                <CustomRadioInput
                  name="salutation"
                  label={t("registrationForm.salutation")}
                  flexDirection={"row"}
                  options={salutationTypeOptions}
                />
                <CustomTextInput
                  name="firstName"
                  placeholder={t("registrationForm.firstName")}
                  label={t("registrationForm.firstName")}
                  required
                />
                <CustomTextInput
                  name="lastName"
                  placeholder={t("registrationForm.lastName")}
                  label={t("registrationForm.lastName")}
                  required
                />
                <CustomTextInput
                  name="profession"
                  placeholder={t("registrationForm.profession")}
                  label={t("registrationForm.profession")}
                />
              </div>

              <div className="two fields">
                <CustomTextInput
                  name="placeOfBirth"
                  placeholder={t("registrationForm.placeOfBirth")}
                  label={t("registrationForm.placeOfBirth")}
                />
                <CustomDateInput
                  name="dateOfBirth"
                  label={t("registrationForm.dateOfBirth")}
                  placeholderText={t("registrationForm.dateOfBirth")}
                  dateFormat="dd.MM.yyyy"
                />
              </div>
              <Divider />

              <Header
                content={t("registrationForm.address")}
                sub
                color="teal"
              />
              <div className="four fields">
                <CustomTextInput
                  name="country"
                  placeholder={t("registrationForm.country")}
                  label={t("registrationForm.country")}
                  required
                />
                <CustomTextInput
                  name="city"
                  placeholder={t("registrationForm.city")}
                  label={t("registrationForm.city")}
                  required
                />
                <CustomTextInput
                  name="street"
                  placeholder={t("registrationForm.street")}
                  label={t("registrationForm.street")}
                  required
                />
                <CustomTextInput
                  name="zipCode"
                  placeholder={t("registrationForm.zipCode")}
                  label={t("registrationForm.zipCode")}
                  required
                />
              </div>
              <Divider />

              <Header
                content={t("registrationForm.contactInformation")}
                sub
                color="teal"
              />
              <div className="three fields">
                <CustomTextInput
                  name="phone"
                  placeholder={t("registrationForm.phone")}
                  label={t("registrationForm.phone")}
                />
                <CustomTextInput
                  name="mobilePhone"
                  placeholder={t("registrationForm.mobilePhone")}
                  label={t("registrationForm.mobilePhone")}
                  required
                />
                <CustomTextInput
                  name="email"
                  placeholder={t("registrationForm.email")}
                  label={t("registrationForm.email")}
                  required
                />
              </div>
              <Divider />

              <Header
                content={t("registrationForm.membership")}
                sub
                color="teal"
              />

              <div className="two fields">
                <CustomRadioInput
                  name="memberShipFee"
                  label=""
                  options={[
                    {
                      key: "120",
                      text: t("registrationForm.minimumContribution"),
                      value: 120,
                    },
                    {
                      key: "240",
                      text: t("registrationForm.individualMembership"),
                      value: 240,
                    },
                    {
                      key: "360",
                      text: t("registrationForm.familyMembership"),
                      value: 360,
                    },
                  ]}
                />

                <CustomRadioInput
                  options={subscriptionTypeOptions}
                  name="subscriptionType"
                  label={t("registrationForm.subscriptionType")}
                  flexDirection={"row"}
                />
              </div>

              <Label style={{ marginTop: "5px" }}>
                {t("registrationForm.membershipFeeDue")}
              </Label>
              <Divider />

              <Header
                content={t("registrationForm.bankInformation")}
                sub
                color="teal"
              />
              <div>
                <div className="one field">
                  <CustomRadioInput
                    name="paymentMethod"
                    label={t("registrationForm.paymentMethod")}
                    flexDirection={"row"}
                    options={paymentMethodOptions.filter(
                      (x) => x.key !== "Block"
                    )}
                  />
                </div>
                {values.paymentMethod === PaymentMethod.Account && (
                  <div className="three fields">
                    <CustomTextInput
                      name="bankName"
                      placeholder={t("registrationForm.bankName")}
                      label={t("registrationForm.bankName")}
                      required
                    />
                    <CustomTextInput
                      name="iban"
                      placeholder={t("registrationForm.iban")}
                      label={t("registrationForm.iban")}
                      required
                    />
                    <CustomTextInput
                      name="bic"
                      placeholder={t("registrationForm.bic")}
                      label={t("registrationForm.bic")}
                      required
                    />
                  </div>
                )}
                <Label style={{ marginTop: "5px" }}>
                  {t("registrationForm.cashPaymentNote")}
                </Label>
              </div>
              <Divider />

              <CustomCheckbox
                label={getCheckboxText(values)}
                name="agreeTerms"
              />
              <Header
                content={t("registrationForm.familyMembers")}
                sub
                color="teal"
              />

              <FieldArray
                name="familyMembers"
                render={(arrayHelpers) => (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          {t("registrationForm.firstName")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("registrationForm.lastName")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("registrationForm.dateOfBirth")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("registrationForm.relationship")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("registrationForm.mekteb")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("registrationForm.actions")}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {values?.familyMembers?.map((familyMember, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <CustomTextInput
                              name={`familyMembers.${index}.firstName`}
                              placeholder={t("registrationForm.firstName")}
                              value={familyMember.firstName}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <CustomTextInput
                              name={`familyMembers.${index}.lastName`}
                              placeholder={t("registrationForm.lastName")}
                              value={familyMember.lastName}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <CustomDateInput
                              name={`familyMembers.${index}.dateOfBirth`}
                              placeholderText={t(
                                "registrationForm.dateOfBirth"
                              )}
                              dateFormat="dd.MM.yyyy"
                              value={familyMember.dateOfBirth?.toString()}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <CustomSelectInput
                              options={relationShipTypeOptions}
                              name={`familyMembers.${index}.relation`}
                              placeholder={t("registrationForm.relationship")}
                              label=""
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <CustomCheckbox
                              label=""
                              name={`familyMembers.${index}.mekteb`}
                            />
                          </Table.Cell>

                          <Table.Cell>
                            <Button
                              type="button"
                              icon="trash"
                              color="red"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                    <Table.Footer fullWidth>
                      <Table.Row>
                        <Table.HeaderCell colSpan="6">
                          <Button
                            type="button"
                            icon="plus"
                            content={t("registrationForm.addFamilyMember")}
                            onClick={() => {
                              const newFamilyMember: NewFamilyMember = {
                                id: uuid(),
                                firstName: "",
                                lastName: "",
                                dateOfBirth: undefined,
                                relation: "",
                              };

                              arrayHelpers.push(newFamilyMember);
                            }}
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                )}
              />

              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated="right"
                positive
                type="submit"
                content={t("registrationForm.confirm")}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Segment>
  );
}
