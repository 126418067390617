export abstract class BasePerson {
  public id: string;
  public createdAt?: Date;
  public updatedAt?: Date;
  public salutation?: string;
  public firstName?: string;
  public lastName?: string;
  public placeOfBirth?: string;
  public dateOfBirth?: Date;
  public city?: string;
  public country?: string;
  public street?: string;
  public zipCode?: string;
  public email?: string;
  public phone?: string;
  public mobilePhone?: string;
  public note?: string;

  constructor() {
    this.id = "";
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.firstName = "";
    this.lastName = "";
    this.salutation = "";
    this.placeOfBirth = "";
    this.dateOfBirth = new Date();
    this.city = "";
    this.country = "";
    this.street = "";
    this.zipCode = "";
    this.email = "";
    this.phone = "";
    this.mobilePhone = "";
    this.note = "";
  }
}
