import { makeAutoObservable, runInAction } from "mobx";
import agent, { ListData } from "../api/agent";
import { Member, MemberFormValues } from "../models/member";
import { DataTableList } from "../common/interfaces/TableData";
import { FilterProps } from "../common/DataTable";
import { NewMember } from "../common/interfaces/NewMember";
import { toast } from "react-toastify";


export default class MemberStore {
    tableData = new DataTableList<Member>();
    selectedMember?: Member = undefined;
    loadingInitial = false;
    editMode = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        return params;
    }

    loadMembers = async (filterProps: FilterProps): Promise<ListData<Member>> => {
        this.loadingInitial = true;
        try {
            const result = await agent.Members.list(filterProps);
            this.setMembers(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            throw error;
        }
    }

    loadUnpaidMemberships = async (filterProps: FilterProps) => {
        this.loadingInitial = true;
        try {
            const result = await agent.Members.getUnpaidMemberships(filterProps);
            this.setMembers(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadPaidMemberships = async (filterProps: FilterProps) => {
        this.loadingInitial = true;
        try {
            const result = await agent.Members.getPaidMemberships(filterProps);
            this.setMembers(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadMember = async (id: string) => {
        let member = this.getMember(id);
        if (member) {
            this.selectedMember = member;
            return member;
        }
        else {
            this.setLoadingInitial(true);
            try {
                member = await agent.Members.details(id);
                this.setMember(member);
                runInAction(() => this.selectedMember = member);
                this.setLoadingInitial(false);
                return member;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setMembers = (dataList: ListData<Member>) => {
        this.tableData = {
            list:
                dataList.data.reduce((acc: Map<string, Member>, item: Member) => {
                    acc.set(item.id!, item);
                    return acc;
                }, new Map<string, Member>()),
            currentPage: dataList.currentPage,
            pageSize: dataList.pageSize,
            totalRecords: dataList.totalRecords,
            orderBy: dataList.orderBy
        }
    }

    private setMember = (member: Member) => {
        this.tableData.list.set(member?.id!, member);
    }

    private getMember = (id: string) => {
        return this.tableData.list.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createMember = async (member: MemberFormValues) => {
        try {
            await agent.Members.create(member);
            const newMember = new Member(member);
            this.setMember(newMember);
            runInAction(() => this.selectedMember = newMember);
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    updateMember = async (member: MemberFormValues) => {
        try {
            await agent.Members.update(member);
            runInAction(() => {
                if (member.id) {
                    const existingMember = this.getMember(member.id);
                    if (existingMember) {
                        Object.assign(existingMember, member);
                        this.selectedMember = existingMember;
                    }
                }
            });
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    deleteMember = async (id: string) => {
        this.loading = true;
        try {
            await agent.Members.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
            return Promise.reject(error);
        }
    }

    approveMember = async (id: string) => {
        this.loading = true;
        try {
            await agent.Members.approveMember(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
            return Promise.reject(error);
        }
    }

    async uploadMembersXlsxData(xlsxData: FormData) {
        this.loading = true;
        try {
            await agent.Members.uploadMembers(xlsxData);
            runInAction(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
            return Promise.reject(error);
        }
    }

    clearSelectedMember = () => {
        this.selectedMember = undefined;
    }

    registerMember = async (member: NewMember) => {
        try {
            await agent.Members.register(member);
            toast.success("Erfolgreich ausgeführt!")
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    loadPendingMembersTable = async (filterProps: FilterProps): Promise<ListData<Member>> => {
        this.loadingInitial = true;
        try {
            const result = await agent.Members.getPendingMembers(filterProps);
            this.setMembers(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            throw error;
        }
    }
}