import { makeAutoObservable, runInAction } from "mobx";
import agent, { ListData } from "../api/agent";
import { User } from "../models/user";
import { router } from "../router/Routes";
import { store } from "./store";
import { UserRegisterDto } from "../models/userRegisterDto";
import { UserRole } from "../common/enums/userRole";
import { DataTableList } from "../common/interfaces/TableData";
import { ChangePassword } from "../common/interfaces/ChangePassword";
import { FilterProps } from "../common/DataTable";

export default class UserStore {
    tableData = new DataTableList<User>();
    selectedUser?: User = undefined;
    loggedUser: User | null = null;
    loadingInitial = false;
    editMode = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        return params;
    }

    get isLoggedIn() {
        return !!this.loggedUser;
    }

    get userRole(): UserRole | undefined {
        return this.loggedUser?.roles[0];
    }

    login = async (creds: UserRegisterDto) => {
        try {
            const user = await agent.Users.login(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => (this.loggedUser = user));

            router.navigate("/home");
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    };

    register = async (creds: UserRegisterDto) => {
        try {
            await agent.Users.register(creds);
            this.loadUsers({});
        } catch (error) {
            throw error;
        }
    };

    changePassword = async (data: ChangePassword) => {
        try {
            return agent.Users.changePassword(data);
        } catch (error) {
            throw error;
        }
    };

    logout = () => {
        store.commonStore.setToken(null);
        this.loggedUser = null;
        router.navigate("/");
    };

    getUser = async () => {
        try {
            const user = await agent.Users.current();
            runInAction(() => (this.loggedUser = user));
        } catch (error) {
            console.log(error);
        }
    };

    deleteUser = async (id: string) => {
        this.loading = true;
        try {
            await agent.Users.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
            return Promise.reject(error);
        }
    }

    loadUsers = async (filterProps: FilterProps) => {
        this.loadingInitial = true;
        try {
            const result = await agent.Users.list(filterProps);
            this.setUsers(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    private setUsers = (dataList: ListData<User>) => {
        this.tableData = {
            list:
                dataList.data.reduce((acc: Map<string, User>, item: User) => {
                    acc.set(item.id!, item);
                    return acc;
                }, new Map<string, User>()),
            currentPage: dataList.currentPage,
            pageSize: dataList.pageSize,
            totalRecords: dataList.totalRecords,
            orderBy: dataList.orderBy
        }
    }
}
