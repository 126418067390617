import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import PaymentForm from "../payments/PaymentForm";
import DataTable, { DataType } from "../../app/common/DataTable";
import { useParams } from "react-router-dom";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import {
  usePaymentMethodOptions,
  usePaymentTypeOptions,
} from "../../app/common/enums/paymentType";
import { useTranslation } from "react-i18next";

export default observer(function MemberPayments() {
  const { t } = useTranslation();
  const { modalStore, paymentStore } = useStore();
  const paymentMethodOptions = usePaymentMethodOptions();
  const paymentTypeOptions = usePaymentTypeOptions();
  const { id } = useParams();
  const columns = [
    {
      header: t("memberPayments.columns.paymentType"),
      options: paymentTypeOptions,
      dataType: DataType.SELECT,
      key: "paymentType",
      filterable: true,
    },
    {
      header: t("memberPayments.columns.paymentMethod"),
      options: paymentMethodOptions,
      dataType: DataType.SELECT,
      key: "paymentMethod",
      filterable: true,
    },
    {
      key: "paidAt",
      header: t("memberPayments.columns.paidAt"),
      dataType: DataType.DATE,
      filterable: true,
    },
    {
      key: "paidAmount",
      header: t("memberPayments.columns.paidAmount"),
      DataType: DataType.NUMBER,
      formatPrice: true,
    },
    {
      key: "reasonForPayment",
      header: t("memberPayments.columns.reasonForPayment"),
      filterable: true,
    },
    { key: "note", header: t("memberPayments.columns.note"), filterable: true },
  ];

  const handleDelete = async (id: string) => {
    await paymentStore.deletePayment(id);
    modalStore.closeModal();
  };

  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: any) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (id) {
      paymentStore.loadPaymentsForMember(id, { orderBy: "paidAt" });
    }
  }, [paymentStore.loadPaymentsForMember, id]);

  return (
    <>
      <Button
        onClick={() => modalStore.openModal(<PaymentForm />, "small")}
        positive
      >
        {t("memberPayments.addPaymentButton")}
      </Button>
      <Button
        onClick={() =>
          paymentStore.downloadDonationProof(
            id!,
            paymentStore.selectedMemberPaymentFilters
          )
        }
        positive
      >
        {t("memberPayments.addGetDonationProofButton")}
      </Button>
      <DataTable
        columns={columns}
        data={paymentStore.tableData}
        onClick={(item) => {
          modalStore.openModal(<PaymentForm id={item.id} />, "small");
        }}
        actionButtons={actionButtons}
        onFilter={(filterProps) => {
          paymentStore.setSelectedMemberPaymentFilters(filterProps);
          paymentStore.loadPaymentsForMember(id!, filterProps);
        }}
        defaultOrderByColumn="paidAt"
        totalColumn="paidAmount"
      />
    </>
  );
});
