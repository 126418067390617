import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { store } from "../stores/store";
import { router } from "../router/Routes";
import { UserRegisterDto } from "../models/userRegisterDto";
import { User } from "../models/user";
import { Member, MemberFormValues } from "../models/member";
import { Payment, PaymentFormValues } from "../models/payment";
import { FamilyMember, FamilyMemberFormValues } from "../models/familyMember";
import { Activity, ActivityFormValues } from "../models/activity";
import { Tenant } from "../models/tenant";
import { DataTableFilterProps } from "../common/interfaces/TableData";
import { Participant, ParticipantFormValues } from "../models/participant";
import { ParticipantFilter } from "../models/participantFilter";
import { CreateParticipant } from "../models/createParticipant";
import { NewMember } from "../common/interfaces/NewMember";
import { ChangePassword } from "../common/interfaces/ChangePassword";
import { MemberDocument } from "../common/interfaces/MemberDocument";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use((config) => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(
    async (response) => response,
    (error: AxiosError) => {
        try {
            const { data, status, config } = error?.response as AxiosResponse;
            let errorMessage = "";

            switch (status) {
                case 400: {
                    if (config.method === "get" && data.errors.hasOwnProperty("id")) {
                        errorMessage = "Greška!";
                    }
                    if (data.errors) {
                        const modalStateErrors = [];
                        for (const key in data.errors) {
                            if (data.errors[key]) {
                                modalStateErrors.push(data.errors[key]);
                            }
                        }
                        modalStateErrors.flat();
                        errorMessage = modalStateErrors.join('\n');
                    } else {
                        errorMessage = data.errors?.join('\n');
                    }
                } break;
                case 401:
                    errorMessage = "Niste logirani!";
                    break;
                case 403: {
                    errorMessage = "Pristup zabranjen!";
                } break;
                case 404: {
                    errorMessage = "Nije pronađeno!";
                    router.navigate("/not-found");
                } break;
                case 429: {
                    errorMessage = "Too many requests!";
                } break;
                case 500: {
                    const jsonErrorObject = JSON.stringify(`${error.message}: ${error.response?.data}`, null, 2);
                    errorMessage = jsonErrorObject ?? "Greška";
                    store.commonStore.setServerError(data);
                } break;
            }
            console.log(errorMessage);
            toast.error(errorMessage, { autoClose: false });
            return Promise.reject(error);
        } catch (error) {
            console.error("Error in response interceptor:", error);
            return Promise.reject(error);
        }
    }
);

const requests = {
    get: <T>(url: string, config?: AxiosRequestConfig) => axios.get<T>(url, config).then(responseBody),
    post: <T>(url: string, body: {}, config?: AxiosRequestConfig) => axios.post<T>(url, body, config).then(responseBody),
    put: <T>(url: string, body: {}, config?: AxiosRequestConfig) => axios.put<T>(url, body, config).then(responseBody),
    patch: <T>(url: string, body: {}, config?: AxiosRequestConfig) => axios.patch<T>(url, body, config).then(responseBody),
    del: <T>(url: string, config?: AxiosRequestConfig) => axios.delete<T>(url, config).then(responseBody),
};

export interface ListData<T> {
    data: T[];
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    orderBy: string;
}

const Members = {
    list: (body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<Member>>("/members/list", body),
    details: (id: string) => requests.get<Member>(`/members/${id}`),
    exportXlsx: (body: DataTableFilterProps = { filters: {} }) => requests.post<void>(`/members/xlsx-export`, body),
    create: (member: MemberFormValues) => requests.post<void>(`/members`, member),
    update: (member: MemberFormValues) => requests.put<void>(`/members/${member.id}`, member),
    delete: (id: string) => requests.del<void>(`/members/${id}`),
    getPendingMembers: (body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<Member>>("/members/pending-members", body),
    getUnpaidMemberships: (body: DataTableFilterProps) => requests.post<ListData<Member>>("/members/unpaid-memberships", body),
    getPaidMemberships: (body: DataTableFilterProps) => requests.post<ListData<Member>>("/members/paid-memberships", body),
    uploadMembers: (data: FormData) => requests.post<any[]>(`/members/upload-xlsx-data`, data, { headers: { "Content-Type": "multipart/form-data" }, }),
    register: (member: NewMember) => requests.post<void>(`/members/registration`, member),
    approveMember: (id: string) => requests.put<void>(`/members/approve-member/${id}`, {}),
    getUnpaidMembershipsXlsxExport: (body: DataTableFilterProps = { filters: {} }) => requests.post<void>("/members/unpaid-memberships/xlsx-export", { ...body, pageSize: 0 }),
    getPaidMembershipsXlsxExport: (body: DataTableFilterProps = { filters: {} }) => requests.post<void>("/members/paid-memberships/xlsx-export", { ...body, pageSize: 0 }),
};

const FamilyMembers = {
    list: (id: string, body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<FamilyMember>>(`/familyMembers/member/${id}`, body),
    details: (id: string) => requests.get<FamilyMember>(`/familyMembers/${id}`),
    create: (familyMember: FamilyMemberFormValues) => requests.post<void>(`/familyMembers`, familyMember),
    update: (familyMember: FamilyMemberFormValues) => requests.put<void>(`/familyMembers/${familyMember.id}`, familyMember),
    delete: (id: string) => requests.del<void>(`/familyMembers/${id}`),
};

const Payments = {
    list: (id: string, body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<Payment>>(`/payments/member/${id}`, body),
    exportXlsx: (body: DataTableFilterProps = { filters: {} }) => requests.post<void>(`/payments/unlinked/xlsx-export`, body),
    getUnlinkedMemberPayments: (body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<Payment>>(`/payments/unlinked-payments`, body),
    details: (id: string) => requests.get<Payment>(`/payments/${id}`),
    create: (payment: PaymentFormValues) => requests.post<void>(`/payments`, payment),
    update: (payment: PaymentFormValues) => requests.put<void>(`/payments/${payment.id}`, payment),
    delete: (id: string) => requests.del<void>(`/payments/${id}`),
    uploadPayments: (data: FormData) => requests.post<any[]>(`/payments/upload-data/`, data, { headers: { "Content-Type": "multipart/form-data" }, }),
    connectUnlinkedPayments: () => requests.put<void>(`/payments/connect-unlinked-payments`, {}),
    generateDonationProof: (id: string, filters: {}) => requests.post<any>(`/payments/member/${id}/donation-proof`, filters, { responseType: 'text' })
};

const Activities = {
    list: (body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<Activity>>(`/activities/list`, body),
    exportXlsx: (body: DataTableFilterProps = { filters: {} }) => requests.post<void>(`/activities/xlsx-export`, body),
    details: (id: string) => requests.get<Activity>(`/activities/${id}`),
    create: (activity: ActivityFormValues) => requests.post<void>(`/activities`, activity),
    update: (activity: ActivityFormValues) => requests.put<void>(`/activities/${activity.id}`, activity),
    delete: (id: string) => requests.del<void>(`/activities/${id}`),
};

const Users = {
    list: (body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<User>>(`/users`, body),
    current: () => requests.get<User>("users/current-user"),
    login: (user: UserRegisterDto) => requests.post<User>("/users/login", user),
    register: (user: UserRegisterDto) => requests.post<User>("/users/register", user),
    delete: (id: string) => requests.del<void>(`/users/${id}`),
    changePassword: (data: ChangePassword) => requests.patch<boolean>(`/users/change-password`, data),
};


const Tenants = {
    list: (params: URLSearchParams) => requests.get<Tenant[]>(`/tenants`, { params }),
    details: (id: string) => requests.get<Tenant>(`/tenants/${id}`),
    create: (tenant: Tenant) => requests.post<void>(`/tenants`, tenant),
    update: (tenant: Tenant) => requests.put<void>(`/tenants/${tenant.id}`, tenant),
    delete: (id: string) => requests.del<void>(`/tenants/${id}`),
};

const Participants = {
    list: (id: string, body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<Participant>>(`/participants/activity/${id}`, body),
    details: (id: string) => requests.get<Participant>(`/participants/${id}`),
    search: (name: string) => requests.get<ParticipantFilter[]>(`/participants/search/${name}`),
    create: (participant: CreateParticipant) => requests.post<void>(`/participants`, participant),
    update: (participant: ParticipantFormValues) => requests.put<void>(`/participants/${participant.id}`, participant),
    delete: (id: string) => requests.del<void>(`/participants/${id}`)
};

const Documents = {
    list: (id: string, body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) => requests.post<ListData<MemberDocument>>(`/documents/member/${id}/list`, body),
    upload: (data: FormData) => requests.post<any[]>(`/documents`, data, { headers: { "Content-Type": "multipart/form-data" }, }),
    delete: (id: string) => requests.del<void>(`/documents/${id}`),
    downloadDocument: (id: string) => requests.get<Blob>(`/documents/${id}/download`, { responseType: "blob" }),
};

const Statistics = {
    getTotalMembers: () => requests.get<number>("/statistics/total-members"),
    getTotalActiveMembers: () => requests.get<number>("/statistics/total-active-members"),
    getTotalInactiveMembers: () => requests.get<number>("/statistics/total-inactive-members"),
    getTotalArchivedMembers: () => requests.get<number>("/statistics/total-archived-members"),
    getTotalPendingMembers: () => requests.get<number>("/statistics/total-pending-members"),
    getTotalMaleMembers: () => requests.get<number>("/statistics/total-male-members"),
    getTotalFemaleMembers: () => requests.get<number>("/statistics/total-female-members"),
    getTotalMembersIncludingFamily: () => requests.get<number>("/statistics/total-members-including-family"),
    getTotalMembershipPaymentsPerYear: () => requests.get<{ year: number, totalPayments: number }[]>("/statistics/total-membership-payments-per-year"),
    getTotalZekatPaymentsPerYear: () => requests.get<{ year: number, totalPayments: number }[]>("/statistics/total-zekat-payments-per-year"),
    getTotalMembersByMembershipFee: () => requests.get<{ membershipGroupFee: number, totalMembers: number }[]>("/statistics/total-members-by-membershipfee"),
};

const agent = {
    Members,
    Users,
    Payments,
    FamilyMembers,
    Activities,
    Tenants,
    Participants,
    Documents,
    Statistics
};

export default agent;
