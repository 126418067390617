import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useStore } from "../../app/stores/store";
import { MemberFormValues } from "../../app/models/member";
import LoadingComponent from "../../app/layout/LoadingComponent";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import CustomTextArea from "../../app/common/form/CustomTextArea";
import CustomDateInput from "../../app/common/form/CustomDateInput";
import TabContainer, { TabData } from "../../app/common/TabContainer";
import MemberPayments from "./MemberPayments";
import MemberFamilyMembers from "./MemberFamilyMembers";
import CustomSelectInput from "../../app/common/form/CustomSelectInput";
import CustomRadioInput from "../../app/common/form/CustomRadioInput";
import { toast } from "react-toastify";
import { useSubscriptionTypeOptions } from "../../app/common/enums/subscriptionType";
import { usePaymentMethodOptions } from "../../app/common/enums/paymentType";
import { useMemberStatusOptions } from "../../app/common/enums/memberStatus";
import { useSalutationTypeOptions } from "../../app/common/enums/salutationType";
import MemberDocuments from "./MemberDocuments";

export default observer(function MemberForm() {
  const { t } = useTranslation();
  const [member, setMember] = useState<MemberFormValues>(
    new MemberFormValues()
  );
  const { memberStore } = useStore();
  const { createMember, updateMember, loadMember, loadingInitial } =
    memberStore;
  const navigate = useNavigate();
  const memberStatusOptions = useMemberStatusOptions();
  const { id } = useParams();
  const commonRequiredMessage = t("members.requiredField");
  const subscriptionTypeOptions = useSubscriptionTypeOptions();
  const paymentMethodOptions = usePaymentMethodOptions();
  const salutationOptions = useSalutationTypeOptions();

  const validationSchema = Yup.object({
    firstName: Yup.string().required(
      `${t("members.firstName")} ${commonRequiredMessage}`
    ),
    lastName: Yup.string().required(
      `${t("members.lastName")} ${commonRequiredMessage}`
    ),
    country: Yup.string().required(
      `${t("members.country")} ${commonRequiredMessage}`
    ),
    city: Yup.string().required(
      `${t("members.city")} ${commonRequiredMessage}`
    ),
    zipCode: Yup.string().required(
      `${t("members.zipCode")} ${commonRequiredMessage}`
    ),
  });

  useEffect(() => {
    if (id)
      loadMember(id).then((member: any) =>
        setMember(new MemberFormValues(member))
      );
  }, [id, loadMember]);

  const handleFormSubmit = async (member: MemberFormValues) => {
    try {
      if (!member.id) {
        const newMember = { ...member, id: uuid() };
        await createMember(newMember);
        navigate(`/members`);
      } else {
        await updateMember(member);
        toast.success(t("members.successfullyUpdated"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tabs: TabData[] = [
    { menuItem: t("members.payments"), render: () => <MemberPayments /> },
    {
      menuItem: t("members.familyMembers"),
      render: () => <MemberFamilyMembers />,
    },
    {
      menuItem: t("members.documents"),
      render: () => <MemberDocuments />,
    },
  ];

  if (loadingInitial)
    return <LoadingComponent content={t("general.loading")} />;

  return (
    <>
      <Header
        as="h2"
        content={
          !member.id
            ? t("members.addMember")
            : `${member.memberNumber} - ${member.firstName} ${
                member.lastName
              } - ${t("members.editData")}`
        }
      />
      <Segment clearing>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={member}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form
              className="ui form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Header content={t("members.basicData")} sub color="teal" />
              <div className="four fields">
                <CustomRadioInput
                  name="salutation"
                  label={t("members.salutation")}
                  flexDirection={"row"}
                  options={salutationOptions}
                />
                <CustomTextInput
                  name="firstName"
                  placeholder={t("members.firstName")}
                  label={t("members.firstName")}
                  required
                />
                <CustomTextInput
                  name="lastName"
                  placeholder={t("members.lastName")}
                  label={t("members.lastName")}
                  required
                />
                <CustomTextInput
                  name="profession"
                  placeholder={t("members.profession")}
                  label={t("members.profession")}
                />
              </div>

              <div className={member.id ? "four fields" : "three fields"}>
                {member.id && (
                  <CustomTextInput
                    name="memberNumber"
                    placeholder={t("members.memberNumber")}
                    label={t("members.memberNumber")}
                    readOnly={true}
                  />
                )}
                <CustomTextInput
                  name="placeOfBirth"
                  placeholder={t("members.placeOfBirth")}
                  label={t("members.placeOfBirth")}
                />
                <CustomDateInput
                  name="dateOfBirth"
                  label={t("members.dateOfBirth")}
                  placeholderText={t("members.dateOfBirth")}
                  dateFormat="dd.MM.yyyy"
                />
                <CustomDateInput
                  name="joinedAt"
                  label={t("members.joiningDate")}
                  placeholderText={t("members.joiningDate")}
                  dateFormat="dd.MM.yyyy"
                />
              </div>

              <div className="two fields">
                <CustomRadioInput
                  name="memberShipFee"
                  label=""
                  options={[
                    {
                      key: "120",
                      text: t("registrationForm.minimumContribution"),
                      value: 120,
                    },
                    {
                      key: "240",
                      text: t("registrationForm.individualMembership"),
                      value: 240,
                    },
                    {
                      key: "360",
                      text: t("registrationForm.familyMembership"),
                      value: 360,
                    },
                  ]}
                />
                <div className="two fields">
                  <CustomRadioInput
                    options={subscriptionTypeOptions}
                    name="subscriptionType"
                    label={t("members.subscriptionType")}
                    flexDirection={"column"}
                  />
                  <CustomSelectInput
                    options={memberStatusOptions}
                    name="status"
                    placeholder={t("members.status")}
                    label={t("members.status")}
                  />
                </div>
              </div>

              <Header content={t("members.address")} sub color="teal" />
              <div className="four fields">
                <CustomTextInput
                  name="country"
                  placeholder={t("members.country")}
                  label={t("members.country")}
                  required
                />
                <CustomTextInput
                  name="city"
                  placeholder={t("members.city")}
                  label={t("members.city")}
                  required
                />
                <CustomTextInput
                  name="street"
                  placeholder={t("members.street")}
                  label={t("members.street")}
                  required
                />
                <CustomTextInput
                  name="zipCode"
                  placeholder={t("members.zipCode")}
                  label={t("members.zipCode")}
                  required
                />
              </div>

              <Header content={t("members.contactData")} sub color="teal" />
              <div className="three fields">
                <CustomTextInput
                  name="phone"
                  placeholder={t("members.phone")}
                  label={t("members.phone")}
                />
                <CustomTextInput
                  name="mobilePhone"
                  placeholder={t("members.mobile")}
                  label={t("members.mobile")}
                />
                <CustomTextInput
                  name="email"
                  placeholder={t("members.email")}
                  label={t("members.email")}
                />
              </div>

              <Header content={t("members.bankData")} sub color="teal" />
              <div className="four fields">
                <CustomRadioInput
                  name="paymentMethod"
                  label={t("members.paymentMethod")}
                  flexDirection={"column"}
                  options={paymentMethodOptions}
                />
                <CustomTextInput
                  name="bankName"
                  placeholder={t("members.bankName")}
                  label={t("members.bankName")}
                />
                <CustomTextInput
                  name="iban"
                  placeholder={t("members.iban")}
                  label={t("members.iban")}
                />
                <CustomTextInput
                  name="bic"
                  placeholder={t("members.bic")}
                  label={t("members.bic")}
                />
              </div>

              <Header content={t("members.otherData")} sub color="teal" />
              <CustomTextArea
                rows={3}
                name="note"
                placeholder={t("members.note")}
                label={t("members.note")}
              />

              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated="right"
                positive
                type="submit"
                content={t("general.confirm")}
              />
              <Button
                as={Link}
                to="/members"
                floated="right"
                type="button"
                content={t("general.cancel")}
              />
            </Form>
          )}
        </Formik>
      </Segment>

      {id && (
        <Segment>
          <TabContainer tabs={tabs} />
        </Segment>
      )}
    </>
  );
});
