import React, { useState } from "react";
import { Button, Checkbox, Form, Message, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useTranslation } from "react-i18next";

export default observer(function UploadPayments() {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [paymentType, setPaymentType] = useState("bank");
  const [actualizeData, setActualizeData] = useState(true);
  const { paymentStore } = useStore();
  const { loading } = paymentStore;

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage(t("uploadPayments.selectCSV"));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("paymentType", paymentType);

    if (actualizeData && paymentType === "block") {
      formData.append("actualizeData", actualizeData as any);
    }

    try {
      await paymentStore.uploadPaymentCsvData(formData);
      setMessage(t("uploadPayments.csvSuccessfullySent"));
    } catch (error) {
      setMessage(t("uploadPayments.csvError"));
    }
  };

  return (
    <Segment>
      {loading && <LoadingComponent content={t("general.loading")} />}

      {!loading && (
        <Form>
          <Form.Field>
            <label>{t("uploadPayments.selectType")}</label>
            <Form.Group inline>
              <Form.Radio
                label={t("uploadPayments.bank.title")}
                value="bank"
                checked={paymentType === "bank"}
                onChange={() => setPaymentType("bank")}
              />
              <Form.Radio
                label={t("uploadPayments.block.title")}
                value="block"
                checked={paymentType === "block"}
                onChange={() => setPaymentType("block")}
              />
              {paymentType === "block" && (
                <Checkbox
                  label={t("uploadPayments.checkBoxActualize")}
                  checked={actualizeData}
                  onChange={(e, { checked }: any) => setActualizeData(checked)}
                />
              )}
            </Form.Group>
          </Form.Field>
          <Form.Field>
            <label>{t(`uploadPayments.${paymentType}.selectFile`)}</label>
            <input
              type="file"
              accept={
                paymentType === "bank"
                  ? ".csv"
                  : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
              onChange={handleFileChange}
            />

            <Message color="yellow" style={{ marginTop: "10px" }}>
              <p>{t("uploadPayments.konto.format")}</p>
              {paymentType === "bank" && (
                <p>
                  "Auftragskonto"; "Buchungstag"; "Valutadatum"; "Buchungstext";
                  "Verwendungszweck"; "Beguenstigter/Zahlungspflichtiger";
                  "Kontonummer"; "BLZ"; "Betrag"; "Waehrung"; "Info"
                </p>
              )}
              {paymentType === "block" && (
                <div>
                  <p>
                    Mitgliedsnummer Nachname Vorname 2021 2022 2023 2024
                    Zahlungsart
                  </p>
                </div>
              )}
            </Message>
          </Form.Field>
          <Button primary onClick={handleUpload} disabled={!file}>
            {t("uploadPayments.sendPayments")}
          </Button>
        </Form>
      )}

      {message && <Message>{message}</Message>}
    </Segment>
  );
});
