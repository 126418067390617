import { RelationShipType } from "../common/enums/relationType";

export interface FamilyMember {
    id?: string;
    firstName: string;
    lastName: string;
    dateOfBirth?: Date | undefined;
    relationShipType?: RelationShipType;
    note?: string;
    memberId?: string;
    mekteb?: boolean;
}

export class FamilyMemberFormValues {
    id?: string = undefined;
    firstName = '';
    lastName = '';
    dateOfBirth?: Date = undefined;
    relationShipType = undefined;
    note = '';
    memberId = undefined;
    mekteb = undefined;

    constructor(familyMember?: FamilyMemberFormValues) {
        if (familyMember) {
            this.id = familyMember.id;
            this.firstName = familyMember.firstName;
            this.lastName = familyMember.lastName;
            this.dateOfBirth = familyMember.dateOfBirth;
            this.relationShipType = familyMember.relationShipType;
            this.note = familyMember.note;
            this.memberId = familyMember.memberId;
            this.mekteb = familyMember.mekteb;
        }
    }

}

export class FamilyMember implements FamilyMember {
    constructor(init?: FamilyMemberFormValues) {
        Object.assign(this, { ...init, dateOfBirth: init?.dateOfBirth?.toISOString() });
    }
}