import { makeAutoObservable } from "mobx";
import agent from "../api/agent";

export default class StatisticStore {
    totalMembers = 0;
    unpaidMemberships = 0;
    totalActiveMembers = 0;
    totalInactiveMembers = 0;
    totalArchivedMembers = 0;
    totalPendingMembers = 0;
    totalMaleMembers = 0;
    totalFemaleMembers = 0;
    totalMembersIncludingFamily = 0;
    totalMembershipPaymentsPerYear: any = [];
    totalZekatPaymentsPerYear: any = [];
    totalMembersByMembershipFee: any = [];
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadStatistics = async () => {
        this.loading = true;
        try {
            const [
                totalMembers,
                totalActiveMembers,
                totalInactiveMembers,
                totalArchivedMembers,
                totalPendingMembers,
                totalMaleMembers,
                totalFemaleMembers,
                totalMembersIncludingFamily,
                totalMembershipPaymentsPerYear,
                totalZekatPaymentsPerYear,
                totalMembersByMembershipFee
            ] = await Promise.all([
                agent.Statistics.getTotalMembers(),
                agent.Statistics.getTotalActiveMembers(),
                agent.Statistics.getTotalInactiveMembers(),
                agent.Statistics.getTotalArchivedMembers(),
                agent.Statistics.getTotalPendingMembers(),
                agent.Statistics.getTotalMaleMembers(),
                agent.Statistics.getTotalFemaleMembers(),
                agent.Statistics.getTotalMembersIncludingFamily(),
                agent.Statistics.getTotalMembershipPaymentsPerYear(),
                agent.Statistics.getTotalZekatPaymentsPerYear(),
                agent.Statistics.getTotalMembersByMembershipFee()
            ]);
            this.totalMembers = totalMembers;
            this.totalActiveMembers = totalActiveMembers;
            this.totalInactiveMembers = totalInactiveMembers;
            this.totalArchivedMembers = totalArchivedMembers;
            this.totalPendingMembers = totalPendingMembers;
            this.totalMaleMembers = totalMaleMembers;
            this.totalFemaleMembers = totalFemaleMembers;
            this.totalMembersIncludingFamily = totalMembersIncludingFamily;
            this.totalMembershipPaymentsPerYear = totalMembershipPaymentsPerYear;
            this.totalZekatPaymentsPerYear = totalZekatPaymentsPerYear;
            this.totalMembersByMembershipFee = totalMembersByMembershipFee;
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    };
}
