import React from "react";
import { useField } from "formik";
import { Form, Checkbox, Label, Icon } from "semantic-ui-react";

interface CheckboxProps {
  label: string;
  name: string;
  required?: boolean;
}

export default function CustomCheckbox({
  label,
  name,
  required,
}: CheckboxProps) {
  const [field, meta, helpers] = useField(name);
  const showError = meta.touched && meta.error;

  return (
    <Form.Field error={showError} style={{ marginTop: "20px" }}>
      <Checkbox
        onChange={() => helpers.setValue(!field.value)}
        checked={field.value}
        label={label}
        name={name}
        id={name}
        toggle
      />
      {required && (
        <Icon
          name="asterisk"
          color="red"
          size="mini"
          style={{ marginTop: "2px", marginLeft: "5px" }}
        />
      )}
      {showError && (
        <Label basic color="red" style={{ marginTop: "5px" }}>
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
