import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';

export interface TabData {
    menuItem: string;
    render: () => React.ReactNode;
}

interface Props {
    tabs: TabData[];
}

export default function TabContainer({ tabs }: Props) {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            panes={tabs.map((tab) => ({
                menuItem: tab.menuItem,
                render: () => <Tab.Pane>{tab.render()}</Tab.Pane>,
            }))}
            activeIndex={activeTab}
            onTabChange={(_, { activeIndex }) => handleTabChange(activeIndex as number)}
        />
    );
};