import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownOnSearchChangeData,
  Header,
  Segment,
} from "semantic-ui-react";
import { ParticipantFilter } from "../../app/models/participantFilter";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface Props {
  activityId: string;
}

export default observer(function ParticipantAutocomplete({
  activityId,
}: Props) {
  const [participants, setParticipants] = useState<
    ParticipantFilter[] | undefined
  >([]);
  const { participantStore, modalStore } = useStore();
  const { searchParticipant, createParticipant, loadParticipants } =
    participantStore;
  const [selectedParticipant, setSelectedParticipant] = useState(null as any);
  const { t } = useTranslation();

  const handleSearchChange = async (
    e: React.SyntheticEvent<HTMLElement>,
    data: DropdownOnSearchChangeData
  ) => {
    const searchQuery = data.searchQuery || "";
    const response = await searchParticipant(searchQuery);
    setParticipants(response);
  };

  const participantOptions = participants?.map((participant) => ({
    key: participant.id,
    text: `${participant.name}`,
    value: participant.id,
  }));

  const handleOnChange = async (e: any, data: any) => {
    setSelectedParticipant({
      key: data.value,
      text: data.text,
      value: data.value,
    });
  };

  const handleCreateParticipant = async () => {
    if (selectedParticipant) {
      await createParticipant({
        id: selectedParticipant.value,
        activityId: activityId,
      });
      modalStore.closeModal();
    }
  };

  return (
    <>
      <Header as="h2" content={t("participants.addParticipant")} />
      <Segment clearing>
        <Dropdown
          fluid
          search
          selection
          options={participantOptions}
          value={selectedParticipant ? selectedParticipant.value : ""}
          onSearchChange={handleSearchChange}
          onChange={handleOnChange}
          placeholder={t("participants.searchPlaceholder")}
          style={{ marginBottom: "10px" }}
        />
        <Button
          onClick={handleCreateParticipant}
          content={t("participants.addParticipant")}
          floated="right"
          positive
        />
      </Segment>
    </>
  );
});
