import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Segment,
  Header,
  SemanticICONS,
  SemanticCOLORS,
} from "semantic-ui-react";
import axios from "axios";
import { Tenant } from "../../app/models/tenant";
import DataTable from "../../app/common/DataTable";
import { useStore } from "../../app/stores/store";
import { User } from "../../app/models/user";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import { observer } from "mobx-react-lite";
import { UserRegisterDto } from "../../app/models/userRegisterDto";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import CustomSelectInput from "../../app/common/form/CustomSelectInput";

export default observer(function CreateUserForm() {
  const [formData, setFormData] = useState<UserRegisterDto>({
    username: "",
    email: "",
    password: "",
    tenantId: "",
  });

  const [tenants, setTenants] = useState<Tenant[]>([]);
  const handleFormSubmit = async (values: UserRegisterDto) => {
    try {
      await userStore.register(values);
      resetFormData();
      console.log("User created successfully");
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const resetFormData = () => {
    setFormData({
      username: "",
      email: "",
      password: "",
      tenantId: "",
    });
  };

  const { userStore, modalStore } = useStore();
  const { loadUsers } = userStore;
  const columns = [
    { key: "userName", header: "Korisničko ime" },
    { key: "email", header: "Email" },
    { key: "tenant.name", header: "Džemat" },
    { key: "phone", header: "Telefon" },
  ];

  const handleDelete = async (id: string) => {
    await userStore.deleteUser(id);
    loadUsers({});
    modalStore.closeModal();
  };

  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: User) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await axios.get("/tenants");
        setTenants(response.data);
      } catch (error) {
        console.error("Error fetching tenants:", error);
      }
    };
    fetchTenants();
    loadUsers({});
  }, []);

  const validationSchema = Yup.object({
    username: Yup.string().required("Korisničko ime je obavezno"),
    email: Yup.string()
      .email("Email adresa nije ispravna")
      .required("Email adresa je obavezna"),
    password: Yup.string()
      .required("Lozinka je obavezna")
      .min(8, "Lozinka mora sadržavati barem 8 znakova")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
        "Lozinka mora sadržavati barem jedno malo i veliko slovo, jedan broj i jedan poseban znak"
      ),
    tenantId: Yup.string().required("Džemat je obavezan"),
  });

  return (
    <>
      <Segment>
        <Header as="h2" content="Dodaj administratora" />
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form
              className="ui form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="two fields">
                <CustomSelectInput
                  options={tenants.map((tenant) => ({
                    key: tenant.id,
                    value: tenant.id,
                    text: tenant.name,
                  }))}
                  name="tenantId"
                  placeholder="Odaberi džemat"
                  label="Odaberi džemat"
                />
                <CustomTextInput
                  name="email"
                  placeholder="Email"
                  label="Email"
                />
              </div>

              <div className="two fields">
                <CustomTextInput
                  name="username"
                  placeholder="Korisničko ime"
                  label="Korisničko ime"
                />

                <CustomTextInput
                  type="password"
                  name="password"
                  placeholder="Lozinka"
                  label="Lozinka"
                />
              </div>

              <Button
                primary
                fluid
                disabled={isSubmitting || !dirty}
                loading={isSubmitting}
                positive
                type="submit"
                content="Kreiraj korisnika"
              />
            </Form>
          )}
        </Formik>
      </Segment>

      <Segment>
        <DataTable
          columns={columns}
          data={userStore.tableData}
          actionButtons={actionButtons}
          onFilter={userStore.loadUsers}
        />
      </Segment>
    </>
  );
});
