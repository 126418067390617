import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import DataTable, { DataType } from "../../app/common/DataTable";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { Member } from "../../app/models/member";
import { useMemberStatusOptions } from "../../app/common/enums/memberStatus";
import agent from "../../app/api/agent";

export default observer(function MemberTable() {
  const { memberStore, modalStore } = useStore();
  const { t } = useTranslation();
  const memberStatusOptions = useMemberStatusOptions();

  const columns = [
    {
      key: "memberNumber",
      header: t("members.memberNumber"),
      dataType: DataType.NUMBER,
      filterable: true,
    },
    {
      key: "status",
      header: t("members.status"),
      options: memberStatusOptions,
      dataType: DataType.SELECT,
      filterable: true,
    },
    { key: "firstName", header: t("members.firstName"), filterable: true },
    { key: "lastName", header: t("members.lastName"), filterable: true },
    { key: "email", header: t("members.email"), filterable: true },
    { key: "phone", header: t("members.phone"), filterable: true },
    { key: "profession", header: t("members.profession"), filterable: true },
    {
      key: "joinedAt",
      header: t("members.joinedAt"),
      filterable: false,
      dataType: DataType.DATE,
    },
  ];

  const handleDelete = async (id: string) => {
    await memberStore.deleteMember(id);
    modalStore.closeModal();
  };

  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: Member) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
            text={t("members.deleteConfirmation")}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={memberStore.tableData}
      clickUrl={(item) => `/update-member/${item.id}`}
      actionButtons={actionButtons}
      onFilter={memberStore.loadMembers}
      defaultOrderByColumn="lastName"
      exportAgentMethod={agent.Members.exportXlsx}
    />
  );
});
