import { makeAutoObservable, runInAction } from "mobx";
import agent, { ListData } from "../api/agent";
import { FamilyMember, FamilyMemberFormValues } from "../models/familyMember";
import { DataTableList, DataTableFilterProps } from "../common/interfaces/TableData";


export default class FamilyMemberStore {
    selectedFamilyMember?: FamilyMember = undefined;
    tableData = new DataTableList<FamilyMember>();
    loadingInitial = false;
    editMode = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        return params;
    }

    loadFamilyMembersForMember = async (memberId: string, body?: DataTableFilterProps): Promise<ListData<FamilyMember>> => {
        this.loadingInitial = true;
        try {
            const result = await agent.FamilyMembers.list(memberId!, body);
            this.setFamilyMembers(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            throw error;
        }
    }

    loadFamilyMember = async (id: string) => {
        let payment = this.getFamilyMember(id);
        if (payment) {
            this.selectedFamilyMember = payment;
            return payment;
        }
        else {
            this.setLoadingInitial(true);
            try {
                payment = await agent.FamilyMembers.details(id);
                this.setFamilyMember(payment);
                runInAction(() => this.selectedFamilyMember = payment);
                this.setLoadingInitial(false);
                return payment;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setFamilyMembers = (dataList: ListData<FamilyMember>) => {
        this.tableData = {
            ...this.tableData,
            list:
                dataList.data.reduce((acc: Map<string, FamilyMember>, item: FamilyMember) => {
                    acc.set(item.id!, item);
                    return acc;
                }, new Map<string, FamilyMember>()),
            totalRecords: dataList.totalRecords
        }
    }

    private setFamilyMember = (payment: FamilyMember) => {
        this.tableData.list.set(payment?.id!, payment);
    }

    private getFamilyMember = (id: string) => {
        return this.tableData.list.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createFamilyMember = async (familyMember: FamilyMemberFormValues) => {
        try {
            await agent.FamilyMembers.create(familyMember);
            const newFamilyMember = new FamilyMember(familyMember);
            this.setFamilyMember(newFamilyMember);
            runInAction(() => this.selectedFamilyMember = newFamilyMember);
        } catch (error) {
            console.log(error);
        }
    }

    updateFamilyMember = async (familyMember: FamilyMemberFormValues) => {
        try {
            await agent.FamilyMembers.update(familyMember);
            runInAction(() => {
                if (familyMember.id) {
                    const existingFamilyMember = this.getFamilyMember(familyMember.id);
                    if (existingFamilyMember) {
                        Object.assign(existingFamilyMember, { ...familyMember });
                        this.selectedFamilyMember = existingFamilyMember;
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    deleteFamilyMember = async (id: string) => {
        this.loading = true;
        try {
            await agent.FamilyMembers.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    clearSelectedFamilyMember = () => {
        this.selectedFamilyMember = undefined;
    }
}