import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Flag } from "semantic-ui-react";

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const storedLanguage = localStorage.getItem("selectedLanguage");
  const [selectedLanguage, setSelectedLanguage] = useState(
    storedLanguage || i18n.language
  );

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  const mapLanguageToFlag = (languageCode: string): any => {
    switch (languageCode) {
      case "en":
        return "gb";
      case "de":
        return "de";
      case "bs":
        return "ba";
      case "tr":
        return "tr";
      case "sq":
        return "al";
      default:
        return "gb";
    }
  };

  return (
    <Dropdown
      item
      text={`${t("navbar.language")}`}
      icon={
        <span style={{ marginLeft: "5px" }}>
          <Flag name={mapLanguageToFlag(selectedLanguage)} />
        </span>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => changeLanguage("bs")}
          active={selectedLanguage === "bs"}
        >
          <Flag name="ba" /> Bosanski
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => changeLanguage("de")}
          active={selectedLanguage === "de"}
        >
          <Flag name="de" /> Deutsch
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => changeLanguage("sq")}
          active={selectedLanguage === "sq"}
        >
          <Flag name="al" /> Shqip
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => changeLanguage("tr")}
          active={selectedLanguage === "tr"}
        >
          <Flag name="tr" /> Türkçe
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => changeLanguage("en")}
          active={selectedLanguage === "en"}
        >
          <Flag name="gb" /> English
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
