import { PaymentMethod, PaymentType } from "../common/enums/paymentType";

export interface Payment {
    id?: string;
    amount: number;
    paidAmount: number;
    paidAt?: Date | undefined;
    note?: string;
    bookingText?: string;
    reasonForPayment?: string;
    memberId?: string;
    paymentType?: PaymentType;
    iban?: string;
    paymentMethod?: PaymentMethod;
    memberNumber?: number;
}

export class PaymentFormValues {
    id?: string = undefined;
    amount = 0;
    paidAmount = 0;
    paidAt?: any = undefined;
    note = '';
    bookingText = '';
    reasonForPayment = '';
    iban = '';
    memberId = undefined;
    paymentType = undefined;
    paymentMethod = undefined;
    memberNumber = 0;
    createdAt?: any = undefined;

    constructor(payment?: PaymentFormValues) {
        if (payment) {
            this.id = payment.id;
            this.paymentType = payment.paymentType;
            this.amount = payment.amount;
            this.paidAmount = payment.paidAmount;
            this.paidAt = payment.paidAt;
            this.note = payment.note;
            this.bookingText = payment.bookingText;
            this.reasonForPayment = payment.reasonForPayment;
            this.memberId = payment.memberId;
            this.iban = payment.iban;
            this.paymentMethod = payment.paymentMethod;
            this.memberNumber = payment.memberNumber;
            this.createdAt = payment.createdAt;
        }
    }

}

export class Payment implements Payment {
    constructor(init?: PaymentFormValues) {
        Object.assign(this, { ...init, paidAt: init?.paidAt?.toISOString(), createdAt: init?.paidAt?.toISOString() });
    }
}