import React from "react";
import { useField } from "formik";
import { Form, Icon, Label, Select } from "semantic-ui-react";

interface Props {
  placeholder: string;
  name: string;
  options: any[];
  label?: string;
  required?: boolean;
}

export default function CustomSelectInput(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <label>{props.label}</label>

      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <Select
          {...props}
          onChange={(e, d) => helpers.setValue(d.value)}
          onBlur={() => helpers.setTouched(true)}
          style={{ marginRight: "5px" }}
          value={field.value || null}
          clearable
          fluid
        />
        {props.required && (
          <Icon
            name="asterisk"
            color="red"
            size="mini"
            style={{ marginTop: "2px" }}
          />
        )}
      </div>

      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
