import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import DataTable, { DataType } from "../../app/common/DataTable";
import { router } from "../../app/router/Routes";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { Activity } from "../../app/models/activity";
import { useTranslation } from "react-i18next";
import agent from "../../app/api/agent";

export default observer(function ActivityTable() {
  const { t } = useTranslation();
  const { activityStore, modalStore } = useStore();
  const columns = [
    { key: "name", header: t("activityTable.nameHeader"), filterable: true },
    { key: "note", header: t("activityTable.noteHeader"), filterable: true },
    {
      key: "createdAt",
      header: t("activityTable.createdAtHeader"),
      dataType: DataType.DATE,
    },
  ];
  const handleDelete = async (id: string) => {
    await activityStore.deleteActivity(id);
    modalStore.closeModal();
  };

  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: Activity) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={activityStore.tableData}
      clickUrl={(item) => `/update-activity/${item.id}`}
      actionButtons={actionButtons}
      onFilter={activityStore.loadActivities}
      exportAgentMethod={agent.Activities.exportXlsx}
    />
  );
});
