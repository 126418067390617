import { makeAutoObservable, runInAction } from "mobx";
import agent, { ListData } from "../api/agent";
import { Activity, ActivityFormValues } from "../models/activity";
import { DataTableFilterProps, DataTableList } from "../common/interfaces/TableData";


export default class ActivityStore {
    tableData = new DataTableList<Activity>();
    selectedActivity?: Activity = undefined;
    loadingInitial = false;
    editMode = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        return params;
    }

    loadActivities = async (filters: any, page = 1, pageSize = 10, orderBy = "") => {
        this.loadingInitial = true;
        try {
            const result = await agent.Activities.list({ filters, page, pageSize, orderBy });
            this.setActivities(result);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadActivity = async (id: string) => {
        let activity = this.getActivity(id);
        if (activity) {
            this.selectedActivity = activity;
            return activity;
        }
        else {
            this.setLoadingInitial(true);
            try {
                activity = await agent.Activities.details(id);
                this.setActivity(activity);
                runInAction(() => this.selectedActivity = activity);
                this.setLoadingInitial(false);
                return activity;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setActivities = (dataList: ListData<Activity>) => {
        this.tableData = {
            list:
                dataList.data.reduce((acc: Map<string, Activity>, item: Activity) => {
                    acc.set(item.id!, item);
                    return acc;
                }, new Map<string, Activity>()),
            currentPage: dataList.currentPage,
            pageSize: dataList.pageSize,
            totalRecords: dataList.totalRecords,
            orderBy: dataList.orderBy
        }
    }

    private setActivity = (activity: Activity) => {
        this.tableData.list.set(activity?.id!, activity);
    }

    private getActivity = (id: string) => {
        return this.tableData.list.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createActivity = async (activity: ActivityFormValues) => {
        try {
            await agent.Activities.create(activity);
            const newActivity = new Activity(activity);
            this.setActivity(newActivity);
            runInAction(() => this.selectedActivity = newActivity);
        } catch (error) {
            console.log(error);
        }
    }

    updateActivity = async (activity: ActivityFormValues) => {
        try {
            await agent.Activities.update(activity);
            runInAction(() => {
                if (activity.id) {
                    const existingActivity = this.getActivity(activity.id);
                    if (existingActivity) {
                        Object.assign(existingActivity, activity);
                        this.selectedActivity = existingActivity;
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    deleteActivity = async (id: string) => {
        this.loading = true;
        try {
            await agent.Activities.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    clearSelectedActivity = () => {
        this.selectedActivity = undefined;
    }
}