import { makeAutoObservable, runInAction } from "mobx";
import agent, { ListData } from "../api/agent";
import { DataTableList } from "../common/interfaces/TableData";
import { MemberDocument } from "../common/interfaces/MemberDocument";

export default class DocumentStore {
    tableData = new DataTableList<MemberDocument>();
    loadingInitial = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadDocumentsForMember = async (memberId: string) => {
        this.loadingInitial = true;
        try {
            const result = await agent.Documents.list(memberId);
            this.setMemberDocuments(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            throw error;
        }
    };

    uploadDocument = async (memberId: string, file: File, description: string) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("memberId", memberId);
            formData.append("description", description);

            await agent.Documents.upload(formData);
        } catch (error) {
            console.error(error);
        }
    };

    private setMemberDocuments = (dataList: ListData<MemberDocument>) => {
        this.tableData = {
            ...this.tableData,
            list:
                dataList.data.reduce((acc: Map<string, MemberDocument>, item: MemberDocument) => {
                    acc.set(item.id!, item);
                    return acc;
                }, new Map<string, MemberDocument>()),
            totalRecords: dataList.totalRecords
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    downloadDocument = async (item: MemberDocument) => {
        try {
            const response = await agent.Documents.downloadDocument(item.id);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", item.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    deleteDocument = async (id: string) => {
        try {
            this.loading = true;
            await agent.Documents.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
}