import React, { useEffect, useState } from "react";
import {
  Button,
  Segment,
  Form,
  SemanticICONS,
  SemanticCOLORS,
} from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import DataTable, { DataType } from "../../app/common/DataTable";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MemberDocument } from "../../app/common/interfaces/MemberDocument";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import { Formik } from "formik";

export default observer(function MemberDocuments() {
  const { t } = useTranslation();
  const { documentStore, modalStore } = useStore();
  const { id } = useParams();
  const [file, setFile] = useState<File | null>(null);

  const columns = [
    { key: "name", header: t("memberDocuments.name") },
    {
      key: "uploadedAt",
      header: t("memberDocuments.uploadedAt"),
      dataType: DataType.DATE,
    },
    {
      key: "description",
      header: t("memberDocuments.description"),
      dataType: DataType.TEXT,
    },
  ];

  useEffect(() => {
    if (id) {
      documentStore.loadDocumentsForMember(id);
    }
  }, [documentStore.loadDocumentsForMember, id]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async (values: { description: string }) => {
    if (file && id) {
      await documentStore.uploadDocument(id, file, values.description);
      setFile(null);
      documentStore.loadDocumentsForMember(id);
    }
  };

  const handleDownload = async (item: MemberDocument) => {
    await documentStore.downloadDocument(item);
  };

  const handleDelete = async (id: string) => {
    await documentStore.deleteDocument(id);
    modalStore.closeModal();
  };

  const actionButtons = [
    {
      icon: "download" as SemanticICONS,
      color: "green" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: MemberDocument) => {
        event.stopPropagation();
        handleDownload(item);
      },
    },
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: MemberDocument) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
            text={t("members.deleteConfirmation")}
          />
        );
      },
    },
  ];

  return (
    <>
      <Segment clearing>
        <Formik initialValues={{ description: "" }} onSubmit={handleUpload}>
          {({ handleSubmit, isValid, isSubmitting, dirty, setFieldValue }) => (
            <Form
              className="ui form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="two fields">
                <Form.Input
                  type="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleFileChange(event);
                    setFieldValue("file", event.currentTarget.files?.[0]);
                  }}
                />
                <CustomTextInput
                  placeholder={t("memberDocuments.description")}
                  name="description"
                />

                <Button
                  disabled={isSubmitting || !dirty || !isValid}
                  loading={isSubmitting}
                  floated="right"
                  positive
                  type="submit"
                  content={t("general.confirm")}
                >
                  {t("memberDocuments.uploadButton")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
      <DataTable
        columns={columns}
        data={documentStore.tableData}
        actionButtons={actionButtons}
        disableRowClick={true}
      />
    </>
  );
});
