import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import DataTable, { DataType } from "../../app/common/DataTable";
import { router } from "../../app/router/Routes";
import { useEffect } from "react";
import { Header } from "semantic-ui-react";
import { useMemberStatusOptions } from "../../app/common/enums/memberStatus";
import { useTranslation } from "react-i18next";
import agent from "../../app/api/agent";

export default observer(function UnpaidMemberships() {
  const { t } = useTranslation();
  const { memberStore } = useStore();
  const { loadPaidMemberships } = memberStore;
  const memberStatusOptions = useMemberStatusOptions();

  useEffect(() => {
    loadPaidMemberships({});
  }, [loadPaidMemberships]);

  const options = () => {
    return Array.from(
      { length: new Date().getFullYear() - 2000 + 1 },
      (_, index) => ({ value: 2000 + index, text: 2000 + index })
    );
  };

  const columns = [
    {
      key: "year",
      header: t("paidMemberships.columns.year"),
      options: options(),
      dataType: DataType.SELECT,
      filterable: true,
      width: "80px",
      defaultValue: new Date().getFullYear(),
    },
    {
      key: "status",
      header: t("paidMemberships.columns.status"),
      options: memberStatusOptions,
      dataType: DataType.SELECT,
      filterable: true,
    },
    {
      key: "firstName",
      header: t("paidMemberships.columns.firstName"),
      filterable: true,
    },
    {
      key: "lastName",
      header: t("paidMemberships.columns.lastName"),
      filterable: true,
    },
    {
      key: "email",
      header: t("paidMemberships.columns.email"),
      filterable: true,
    },
    {
      key: "phone",
      header: t("paidMemberships.columns.phone"),
      filterable: true,
    },
    {
      key: "membershipFee",
      header: t("paidMemberships.columns.membershipFee"),
      filterable: false,
      DataType: DataType.NUMBER,
      formatPrice: true,
    },
    {
      key: "totalDebt",
      header: t("paidMemberships.columns.totalDebt"),
      filterable: false,
      DataType: DataType.NUMBER,
      formatPrice: true,
    },
    {
      key: "totalPaid",
      header: t("paidMemberships.columns.totalPaid"),
      filterable: false,
      DataType: DataType.NUMBER,
      formatPrice: true,
    },
  ];

  return (
    <>
      <Header as="h2" content={t("paidMemberships.pageTitle")} />
      <DataTable
        columns={columns}
        data={memberStore.tableData}
        clickUrl={(item) => `/update-member/${item.id}`}
        onFilter={memberStore.loadPaidMemberships}
        defaultOrderByColumn="lastName"
        exportAgentMethod={agent.Members.getPaidMembershipsXlsxExport}
      />
    </>
  );
});
