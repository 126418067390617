import React from "react";
import { useField } from "formik";
import { Form, Icon, Label, Radio } from "semantic-ui-react";

interface RadioOption {
  key: string;
  text: string;
  value: any;
}

interface Props {
  name: string;
  options: RadioOption[];
  label?: string;
  required?: boolean;
  flexDirection?: any;
}

export default function CustomRadioInput(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (value: string) => {
    helpers.setValue(value);
    helpers.setTouched(true);
  };

  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <label>{props.label}</label>

      <div
        style={{
          display: "flex",
          flexDirection: props.flexDirection || "column",
        }}
      >
        {props.options.map((option) => (
          <Radio
            key={option.key}
            label={option.text}
            name={props.name}
            value={option.value}
            checked={field.value === option.value}
            onChange={() => handleChange(option.value)}
            style={{ marginTop: "5px", paddingLeft: "5px" }}
          />
        ))}
      </div>

      {props.required && (
        <Icon
          name="asterisk"
          color="red"
          size="mini"
          style={{ marginTop: "2px" }}
        />
      )}

      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
