import { Member } from "./member";
import { User } from "./user";

export interface Activity {
    id?: string;
    name: string;
    userId: string;
    createdByUser?: User;
    note: string;
    isActive: boolean;
    createdAt?: Date;
}

export class ActivityFormValues {
    id?: string = undefined;
    name = '';
    userId = '';
    createdByUser?: User;
    note = '';
    isActive = true;
    createdAt = null;

    constructor(activity?: ActivityFormValues) {
        if (activity) {
            this.id = activity.id;
            this.name = activity.name;
            this.userId = activity.userId;
            this.createdByUser = activity.createdByUser;
            this.note = activity.note;
            this.isActive = activity.isActive;
            this.createdAt = activity.createdAt;
        }
    }
}

export class Activity implements Activity {
    constructor(init?: ActivityFormValues) {
        Object.assign(this, init);
    }
}
