import { useTranslation } from "react-i18next";

export enum RelationShipType {
    Partner = 1,
    Son,
    Daughter
}

export const RelationShipTypeStrings: {
    [key in RelationShipType]: string;
} = {
    [RelationShipType.Partner]: 'partner',
    [RelationShipType.Daughter]: 'daughter',
    [RelationShipType.Son]: 'son',
};

export const useRelationShipTypeOptions = () => {
    const { t } = useTranslation();

    return Object.keys(RelationShipType)
        .filter((key: any) => !isNaN(Number(RelationShipType[key])))
        .map((key: any) => ({
            key: key,
            value: RelationShipType[key],
            text: t(`general.${(RelationShipTypeStrings as any)[RelationShipType[key]]}`),
        }));
};
