import { makeAutoObservable, runInAction } from "mobx";
import { Participant, ParticipantFormValues } from "../models/participant";
import { DataTableList } from "../common/interfaces/TableData";
import agent, { ListData } from "../api/agent";
import { CreateParticipant } from "../models/createParticipant";
import { FilterProps } from "../common/DataTable";

export default class ParticipantStore {
    tableData = new DataTableList<Participant>();
    selectedParticipant?: Participant = undefined;
    loadingInitial = false;
    editMode = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        return params;
    }

    loadParticipants = async (activityId: string, filterProps: FilterProps): Promise<ListData<Participant>> => {
        this.loadingInitial = true;
        try {
            const result = await agent.Participants.list(activityId, filterProps);
            this.setParticipants(result);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            throw error;
        }
    }

    loadParticipant = async (id: string) => {
        let participant = this.getParticipant(id);
        if (participant) {
            this.selectedParticipant = participant;
            return participant;
        }
        else {
            this.setLoadingInitial(true);
            try {
                participant = await agent.Participants.details(id);
                this.setParticipant(participant);
                runInAction(() => this.selectedParticipant = participant);
                this.setLoadingInitial(false);
                return participant;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setParticipants = (dataList: ListData<Participant>) => {
        this.tableData = {
            list:
                dataList.data.reduce((acc: Map<string, Participant>, item: Participant) => {
                    acc.set(item.id!, item);
                    return acc;
                }, new Map<string, Participant>()),
            currentPage: dataList.currentPage,
            pageSize: dataList.pageSize,
            totalRecords: dataList.totalRecords,
            orderBy: dataList.orderBy
        }
    }

    private setParticipant = (participant: Participant) => {
        this.tableData.list.set(participant?.id!, participant);
    }

    private getParticipant = (id: string) => {
        return this.tableData.list.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createParticipant = async (participant: CreateParticipant) => {
        try {
            await agent.Participants.create(participant);
            runInAction(() => {
                this.loadParticipants(participant.activityId, {});
            });
        } catch (error) {
            console.log(error);
        }
    }

    searchParticipant = async (name: string) => {
        this.setLoadingInitial(true);
        try {
            const participants = await agent.Participants.search(name);
            this.setLoadingInitial(false);
            return participants;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    updateParticipant = async (participant: ParticipantFormValues) => {
        try {
            await agent.Participants.update(participant);
            runInAction(() => {
                if (participant.id) {
                    const existingParticipant = this.getParticipant(participant.id);
                    if (existingParticipant) {
                        Object.assign(existingParticipant, participant);
                        this.selectedParticipant = existingParticipant;
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    deleteParticipant = async (id: string) => {
        this.loading = true;
        try {
            await agent.Participants.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    clearSelectedParticipant = () => {
        this.selectedParticipant = undefined;
    }
}