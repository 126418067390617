import React from "react";
import { useField } from "formik";
import { Form, Label, Icon } from "semantic-ui-react";

interface Props {
  placeholder: string;
  name: string;
  label?: string;
  type?: string;
  value?: string;
  onChange?: any;
  required?: boolean;
  readOnly?: boolean;
}

export default function CustomTextInput(props: Props) {
  const [field, meta] = useField(props.name);
  const showError = meta.touched && meta.error;

  return (
    <Form.Field error={showError}>
      <label>{props.label}</label>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <input {...field} {...props} style={{ marginRight: "5px" }} />
        {props.required && (
          <Icon
            name="asterisk"
            color="red"
            size="mini"
            style={{ marginTop: "2px" }}
          />
        )}
      </div>
      {showError && (
        <Label basic color="red" style={{ marginTop: "5px" }}>
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
