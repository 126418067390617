import { useTranslation } from "react-i18next";

export enum SalutationType {
    Mr = "herr",
    Mrs = "frau",
}

export const SalutationTypeStrings: {
    [key in SalutationType]: string;
} = {
    [SalutationType.Mr]: 'mr',
    [SalutationType.Mrs]: 'mrs',
};

export const useSalutationTypeOptions = () => {
    const { t } = useTranslation();

    return Object.keys(SalutationType)
        .filter((key: any) => (SalutationType as any)[key])
        .map((key: any) => ({
            key: key,
            value: (SalutationType as any)[key],
            text: t(`members.${(SalutationTypeStrings as any)[(SalutationType as any)[key]]}`),
        }));
};

export const useGenderTypeOptions = () => {
    const { t } = useTranslation();

    return Object.keys(SalutationType)
        .filter((key: any) => (SalutationType as any)[key])
        .map((key: any) => ({
            key: key,
            value: (SalutationType as any)[key],
            text: t(`dashboard.${(SalutationTypeStrings as any)[(SalutationType as any)[key]]}`),
        }));
};
