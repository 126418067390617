import { PaymentMethod } from "../enums/paymentType";
import { SalutationType } from "../enums/salutationType";
import { SubscriptionType } from "../enums/subscriptionType";
import { NewFamilyMember } from "./NewFamilyMember";

export interface NewMember {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: Date;
    placeOfBirth?: string;
    city?: string;
    country?: string;
    street?: string;
    zipCode?: string;
    email?: string;
    phone?: string;
    mobilePhone?: string;
    profession?: string;
    paymentMethod?: PaymentMethod;
    bankName?: string;
    iban?: string;
    bic?: string;
    familyMembers: NewFamilyMember[];
    memberShipFee?: number;
    subscriptionType?: SubscriptionType;
    joinedAt?: Date;
}

export const getDefaultNewMemberValues = (): NewMember => {
    return {
        salutation: SalutationType.Mr,
        firstName: "",
        lastName: "",
        dateOfBirth: undefined,
        placeOfBirth: "",
        city: "",
        country: "",
        street: "",
        zipCode: "",
        email: "",
        phone: "",
        mobilePhone: "",
        profession: "",
        paymentMethod: PaymentMethod.Account,
        bankName: "",
        iban: "",
        bic: "",
        familyMembers: [],
        memberShipFee: 240,
        subscriptionType: SubscriptionType.Monthly,
        joinedAt: new Date()
    };
};