import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Segment,
  Statistic,
  Header,
  Card,
} from "semantic-ui-react";
import { Bar, Pie } from "react-chartjs-2";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useMemberStatusOptions } from "../../app/common/enums/memberStatus";
import {
  useGenderTypeOptions,
  useSalutationTypeOptions,
} from "../../app/common/enums/salutationType";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../../app/layout/LoadingComponent";

const Dashboard = () => {
  const { statisticStore } = useStore();
  const memberStatusOptions = useMemberStatusOptions();
  const salutationTypeOptions = useGenderTypeOptions();
  const { totalMembers, totalActiveMembers } = statisticStore;
  const { t } = useTranslation();

  useEffect(() => {
    statisticStore.loadStatistics();
  }, [statisticStore]);

  if (statisticStore.loading)
    return <LoadingComponent content={t("general.loading")} />;

  return (
    <Container style={{ marginTop: "20px" }}>
      <Grid stackable>
        <Grid.Row columns={4}>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Statistic>
                  <Statistic.Value>
                    {statisticStore.totalActiveMembers}
                  </Statistic.Value>
                  <Statistic.Label>
                    {t("dashboard.activeMembers")}
                  </Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Statistic>
                  <Statistic.Value>
                    {statisticStore.totalInactiveMembers}
                  </Statistic.Value>
                  <Statistic.Label>
                    {t("dashboard.inactiveMembers")}
                  </Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Statistic>
                  <Statistic.Value>
                    {statisticStore.totalPendingMembers}
                  </Statistic.Value>
                  <Statistic.Label>
                    {t("dashboard.pendingMembers")}
                  </Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Statistic>
                  <Statistic.Value>
                    {statisticStore.totalArchivedMembers}
                  </Statistic.Value>
                  <Statistic.Label>
                    {t("dashboard.archivedMembers")}
                  </Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Statistic>
                  <Statistic.Value>
                    {statisticStore.totalMembersIncludingFamily}
                  </Statistic.Value>
                  <Statistic.Label>
                    {t("dashboard.totalMembersIncludingFamily")}
                  </Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Statistic>
                  <Statistic.Value>
                    {statisticStore.totalMaleMembers}
                  </Statistic.Value>
                  <Statistic.Label>
                    {t("dashboard.maleMembers")}
                  </Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Statistic>
                  <Statistic.Value>
                    {statisticStore.totalFemaleMembers}
                  </Statistic.Value>
                  <Statistic.Label>
                    {t("dashboard.femaleMembers")}
                  </Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h3" textAlign="center">
                {t("dashboard.totalMembershipPaymentsPerYear")}
              </Header>
              <Bar
                data={{
                  labels: statisticStore.totalMembershipPaymentsPerYear.map(
                    (payment: any) => payment.year.toString()
                  ),
                  datasets: [
                    {
                      label: t("general.membership"),
                      data: statisticStore.totalMembershipPaymentsPerYear.map(
                        (payment: any) => payment.totalPayments
                      ),
                      backgroundColor: "#36A2EB",
                    },
                  ],
                }}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Header as="h3" textAlign="center">
                {t("dashboard.totalZekatPaymentsPerYear")}
              </Header>
              <Bar
                data={{
                  labels: statisticStore.totalZekatPaymentsPerYear.map(
                    (payment: any) => payment.year.toString()
                  ),
                  datasets: [
                    {
                      label: t("general.zekat"),
                      data: statisticStore.totalZekatPaymentsPerYear.map(
                        (payment: any) => payment.totalPayments
                      ),
                      backgroundColor: "#36A2EB",
                    },
                  ],
                }}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h3" textAlign="center">
                {t("dashboard.membersByStatus")}
              </Header>
              <Pie
                data={{
                  labels: memberStatusOptions.map((option) => option.text),
                  datasets: [
                    {
                      data: [
                        statisticStore.totalActiveMembers,
                        statisticStore.totalInactiveMembers,
                        statisticStore.totalPendingMembers,
                        statisticStore.totalArchivedMembers,
                      ],
                      backgroundColor: [
                        "#36A2EB",
                        "#FFCE56",
                        "#FF6384",
                        "#D3D3D3",
                      ],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          var label = context.label || "";
                          if (label) {
                            label += ": ";
                          }
                          if (context.parsed) {
                            label +=
                              context.parsed +
                              " (" +
                              ((context.parsed / totalMembers) * 100).toFixed(
                                2
                              ) +
                              "%)";
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Header as="h3" textAlign="center">
                {t("dashboard.membersByGender")}
              </Header>
              <Pie
                data={{
                  labels: salutationTypeOptions.map((option) => option.text),
                  datasets: [
                    {
                      data: [
                        statisticStore.totalMaleMembers,
                        statisticStore.totalFemaleMembers,
                      ],
                      backgroundColor: ["#36A2EB", "#FF6384"],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          var label = context.label || "";
                          if (label) {
                            label += ": ";
                          }
                          if (context.parsed) {
                            label +=
                              context.parsed +
                              " (" +
                              (
                                (context.parsed / totalActiveMembers) *
                                100
                              ).toFixed(2) +
                              "%)";
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h3" textAlign="center">
                {t("dashboard.membersByMembershipfeeGroup")}
              </Header>
              <Pie
                data={{
                  labels: [
                    {
                      value: 120,
                      text: t("registrationForm.minimumContribution"),
                    },
                    {
                      value: 240,
                      text: t("registrationForm.individualMembership"),
                    },
                    {
                      value: 360,
                      text: t("registrationForm.familyMembership"),
                    },
                  ].map((option) => option.text),
                  datasets: [
                    {
                      label: t("general.membersByMembershipfeeGroup"),
                      data: statisticStore.totalMembersByMembershipFee.map(
                        (item: any) => item.totalMembers
                      ),
                      backgroundColor: ["#36A2EB", "#FFCE56", "#2afc1b"],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "bottom",
                      align: "start",
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          var label = context.label || "";
                          if (label) {
                            label += ": ";
                          }
                          if (context.parsed) {
                            label +=
                              context.parsed +
                              " (" +
                              (
                                (context.parsed / totalActiveMembers) *
                                100
                              ).toFixed(2) +
                              "%)";
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default observer(Dashboard);
