import { makeAutoObservable, runInAction } from "mobx";
import agent, { ListData } from "../api/agent";
import { Payment, PaymentFormValues } from "../models/payment";
import { DataTableFilterProps, DataTableList } from "../common/interfaces/TableData";


export default class PaymentStore {
    tableData = new DataTableList<Payment>();
    selectedPayment?: Payment = undefined;
    loadingInitial = false;
    editMode = false;
    loading = false;
    selectedMemberPaymentFilters = {};

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        return params;
    }

    loadUnLinkedPayments = async (body?: DataTableFilterProps) => {
        this.loadingInitial = true;
        try {
            const result = await agent.Payments.getUnlinkedMemberPayments(body);
            this.setPayments(result);
            runInAction(() => {
                this.setLoadingInitial(false);
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.setLoadingInitial(false);
            });
        }
    }

    loadPaymentsForMember = async (id: string, body?: DataTableFilterProps) => {
        this.loadingInitial = true;
        try {
            const result = await agent.Payments.list(id, body);
            this.setPayments(result);
            runInAction(() => {
                this.setLoadingInitial(false);
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.setLoadingInitial(false);
            });
        }
    }

    loadPayment = async (id: string) => {
        let payment = this.getPayment(id);
        if (payment) {
            this.selectedPayment = payment;
            return payment;
        }
        else {
            this.setLoadingInitial(true);
            try {
                payment = await agent.Payments.details(id);
                this.setPayment(payment);
                runInAction(() => this.selectedPayment = payment);
                this.setLoadingInitial(false);
                return payment;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setPayments = (dataList: ListData<Payment>) => {
        this.tableData = {
            list:
                dataList.data.reduce((acc: Map<string, Payment>, item: Payment) => {
                    acc.set(item.id!, item);
                    return acc;
                }, new Map<string, Payment>()),
            currentPage: dataList.currentPage,
            pageSize: dataList.pageSize,
            totalRecords: dataList.totalRecords,
            orderBy: dataList.orderBy
        }
    }

    private setPayment = (payment: Payment) => {
        this.tableData.list.set(payment?.id!, payment);
    }

    private getPayment = (id: string) => {
        return this.tableData.list.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createPayment = async (payment: PaymentFormValues) => {
        try {
            await agent.Payments.create(payment);
            const newPayment = new Payment(payment);
            this.setPayment(newPayment);
            runInAction(() => this.selectedPayment = newPayment);
        } catch (error) {
            console.log(error);
        }
    }

    updatePayment = async (payment: PaymentFormValues) => {
        try {
            await agent.Payments.update(payment);
            runInAction(() => {
                if (payment.id) {
                    const existingPayment = this.getPayment(payment.id);
                    if (existingPayment) {
                        Object.assign(existingPayment, { ...payment, paidAt: payment?.paidAt?.toISOString() });
                        this.selectedPayment = existingPayment;
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    deletePayment = async (id: string) => {
        this.loading = true;
        try {
            await agent.Payments.delete(id);
            runInAction(() => {
                this.tableData.list.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    async uploadPaymentCsvData(uploadCsvData: FormData) {
        this.loading = true;
        try {
            await agent.Payments.uploadPayments(uploadCsvData);
            runInAction(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
                throw error;
            })
        }
    }

    connectUnLinkedPayments = async () => {
        this.loading = true;
        try {
            await agent.Payments.connectUnlinkedPayments();
            runInAction(() => {
                this.loadUnLinkedPayments();
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    downloadDonationProof = async (memberId: string, filters: any = {}) => {
        this.loading = true;
        try {
            const response = await agent.Payments.generateDonationProof(memberId, filters);
            const byteCharacters = atob(response.replaceAll("\"", ""));
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = 'Spendebescheinigung.pdf';
            document.body.appendChild(link);
            link.click();

            this.loading = false;
        } catch (error) {
            console.error('Error downloading donation proof:', error);
            this.loading = false;
        }
    }

    clearSelectedPayment = () => {
        this.selectedPayment = undefined;
    }

    setSelectedMemberPaymentFilters = (filters: any) => {
        this.selectedMemberPaymentFilters = filters;
    }
}