import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Button,
  Grid,
  Header,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import PaymentForm from "./PaymentForm";
import DataTable, { DataType } from "../../app/common/DataTable";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { usePaymentMethodOptions } from "../../app/common/enums/paymentType";
import { useTranslation } from "react-i18next";
import agent from "../../app/api/agent";

export default observer(function UnLinkedPaymentsTable() {
  const { t } = useTranslation();
  const { modalStore, paymentStore } = useStore();
  const columns = [
    {
      key: "paymentMethod",
      header: t("unLinkedPayments.columns.paymentMethod"),
      options: usePaymentMethodOptions(),
      dataType: DataType.SELECT,
      filterable: true,
    },
    {
      key: "iban",
      header: t("unLinkedPayments.columns.iban"),
      dataType: DataType.TEXT,
      filterable: true,
    },
    {
      key: "paidAt",
      header: t("unLinkedPayments.columns.paidAt"),
      dataType: DataType.DATE,
    },
    {
      key: "paidAmount",
      header: t("unLinkedPayments.columns.paidAmount"),
      dataType: DataType.NUMBER,
      formatPrice: true,
    },
    {
      key: "reasonForPayment",
      header: t("unLinkedPayments.columns.reasonForPayment"),
      filterable: true,
    },
    {
      key: "note",
      header: t("unLinkedPayments.columns.note"),
      filterable: true,
    },
    {
      key: "createdAt",
      header: t("unLinkedPayments.columns.createdAt"),
      dataType: DataType.DATE,
    },
  ];

  const handleDelete = async (id: string) => {
    await paymentStore.deletePayment(id);
    modalStore.closeModal();
  };

  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: any) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  useEffect(() => {
    paymentStore.loadUnLinkedPayments();
  }, []);

  const onLinkPaymentsClick = () => {
    modalStore.openModal(
      <ConfirmComponent
        onYes={async () => {
          modalStore.closeModal();
          await paymentStore.connectUnLinkedPayments();
        }}
        onCancel={modalStore.closeModal}
      />
    );
  };

  return (
    <>
      <Grid columns="equal">
        <Grid.Column>
          <Header as="h2" content={t("unLinkedPayments.pageTitle")} />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            disabled={paymentStore.loading}
            onClick={onLinkPaymentsClick}
            positive
          >
            {t("unLinkedPayments.linkPaymentsButton")}
          </Button>
        </Grid.Column>
      </Grid>

      {paymentStore.loading && (
        <LoadingComponent content={t("unLinkedPayments.connectingPayments")} />
      )}

      <DataTable
        columns={columns}
        data={paymentStore.tableData}
        onClick={(item) =>
          modalStore.openModal(<PaymentForm id={item.id} />, "small")
        }
        actionButtons={actionButtons}
        onFilter={paymentStore.loadUnLinkedPayments}
        defaultOrderByColumn="iban"
        exportAgentMethod={agent.Payments.exportXlsx}
      />
    </>
  );
});
