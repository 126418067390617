import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

interface Props {
  text?: string;
  onYes: () => void;
  onCancel: () => void;
}

export default observer(function ConfirmComponent({
  text,
  onYes,
  onCancel,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="ui message">
      <div className="header">{text || t("general.areYouSure")}</div>
      <ul className="list">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Button onClick={onYes} floated="right" positive>
                {t("general.yes")}
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button onClick={onCancel} negative>
                {t("general.no")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ul>
    </div>
  );
});
