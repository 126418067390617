import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import DataTable, { DataType } from "../../app/common/DataTable";
import { useParams } from "react-router-dom";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";
import FamilyMemberForm from "../familyMembers/FamilyMemberForm";
import { FamilyMember } from "../../app/models/familyMember";
import {
  RelationShipTypeStrings,
  useRelationShipTypeOptions,
} from "../../app/common/enums/relationType";
import { useTranslation } from "react-i18next";

export default observer(function MemberFamilyMembers() {
  const { t } = useTranslation();
  const { modalStore, familyMemberStore } = useStore();
  const relationShipTypeOptions = useRelationShipTypeOptions();
  const { id } = useParams();

  const columns = [
    { key: "firstName", header: t("memberFamilyMembers.firstName") },
    { key: "lastName", header: t("memberFamilyMembers.lastName") },
    {
      key: "relationShipType",
      header: t("memberFamilyMembers.relationship"),
      options: relationShipTypeOptions,
      dataType: DataType.SELECT,
    },
    {
      key: "dateOfBirth",
      header: t("memberFamilyMembers.dateOfBirth"),
      dataType: DataType.DATE,
    },
    {
      key: "mekteb",
      header: t("memberFamilyMembers.mekteb"),
      dataType: DataType.BOOLEAN,
    },
    { key: "note", header: t("memberFamilyMembers.note") },
  ];
  const handleDelete = async (id: string) => {
    await familyMemberStore.deleteFamilyMember(id);
    modalStore.closeModal();
  };
  const actionButtons = [
    {
      icon: "trash" as SemanticICONS,
      color: "red" as SemanticCOLORS,
      onClick: (event: React.MouseEvent, item: FamilyMember) => {
        event.stopPropagation();
        modalStore.openModal(
          <ConfirmComponent
            onYes={async () => handleDelete(item.id!)}
            onCancel={modalStore.closeModal}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (id) {
      familyMemberStore.loadFamilyMembersForMember(id);
    }
  }, [familyMemberStore.loadFamilyMembersForMember, id]);

  return (
    <>
      <Button
        onClick={() => modalStore.openModal(<FamilyMemberForm />, "small")}
        positive
      >
        {t("memberFamilyMembers.addButton")}
      </Button>
      <DataTable
        columns={columns}
        data={familyMemberStore.tableData}
        onClick={(item) =>
          modalStore.openModal(<FamilyMemberForm id={item.id} />, "small")
        }
        actionButtons={actionButtons}
        onFilter={(filterProps) =>
          familyMemberStore.loadFamilyMembersForMember(id!, filterProps)
        }
        defaultOrderByColumn="firstName"
      />
    </>
  );
});
