import { useTranslation } from "react-i18next";

export enum MemberStatus {
    Active = 1,
    Inactive = 2,
    Pending = 3,
    Archived = 4
}

export const MemberStatusStrings: {
    [key in MemberStatus]: string;
} = {
    [MemberStatus.Active]: 'active',
    [MemberStatus.Inactive]: 'inactive',
    [MemberStatus.Pending]: 'pending',
    [MemberStatus.Archived]: 'archived',
};

export const useMemberStatusOptions = () => {
    const { t } = useTranslation();

    return Object.keys(MemberStatus)
        .filter((key: any) => !isNaN(Number(MemberStatus[key])))
        .map((key: any) => {
            return ({
                key: MemberStatus[key],
                value: MemberStatus[key],
                text: t(`general.${(MemberStatusStrings as any)[MemberStatus[key]]}`),
            })
        });
};