import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import germanTranslation from './locales/de/translation.json';
import bosnianTranslation from './locales/bs/translation.json';
import turkishTranslation from './locales/tr/translation.json';
import albanianTranslation from './locales/sq/translation.json';
import englishTranslation from './locales/en/translation.json';


i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: englishTranslation },
            de: { translation: germanTranslation },
            bs: { translation: bosnianTranslation },
            tr: { translation: turkishTranslation },
            sq: { translation: albanianTranslation }
        },
        lng: 'bs',
        fallbackLng: 'bs',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;