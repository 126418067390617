import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import App from "../layout/App";
import MemberForm from "../../features/members/MemberForm";
import PaymentForm from "../../features/payments/PaymentForm";
import ActivityList from "../../features/activities/ActivityList";
import ActivityForm from "../../features/activities/ActivityForm";
import UnpaidMemberships from "../../features/members/UnpaidMemberships";
import PaidMemberships from "../../features/members/PaidMemberships";
import UploadPayments from "../../features/payments/UploadPayments";
import CreateUserForm from "../../features/users/CreateUserForm";
import CreateTenantForm from "../../features/tenants/CreateTenantForm";
import HomePage from "../../features/home/HomePage";
import UploadMembers from "../../features/members/UploadMembers";
import RegisterMemberForm from "../../features/members/RegisterMemberForm";
import PendingMembersTable from "../../features/members/PendingMembersTable";
import MyProfile from "../../features/users/MyProfile";
import PaymentsTable from "../../features/payments/UnLinkedPaymentsTable";
import MemberTable from "../../features/members/MemberTable";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          { path: "home", element: <HomePage /> },
          { path: "members", element: <MemberTable /> },
          { path: "pending-members", element: <PendingMembersTable /> },
          {
            path: "create-member",
            element: <MemberForm key="create-member" />,
          },
          { path: "unlinked-payments", element: <PaymentsTable /> },
          { path: "upload-payments", element: <UploadPayments /> },
          { path: "upload-members", element: <UploadMembers /> },
          {
            path: "update-member/:id",
            element: <MemberForm key="update-member" />,
          },
          {
            path: "update-payment/:id",
            element: <PaymentForm key="update-payment" />,
          },
          { path: "activities", element: <ActivityList /> },
          {
            path: "create-activity",
            element: <ActivityForm key="create-activity" />,
          },
          {
            path: "update-activity/:id",
            element: <ActivityForm key="update-activity" />,
          },
          {
            path: "unpaid-memberships",
            element: <UnpaidMemberships key="unpaid-memberships" />,
          },
          {
            path: "paid-memberships",
            element: <PaidMemberships key="paid-memberships" />,
          },
          {
            path: "users-create",
            element: <CreateUserForm key="create-users" />,
          },
          {
            path: "tenants-create",
            element: <CreateTenantForm key="create-tenants" />,
          },
          {
            path: "my-profile",
            element: <MyProfile key="my-profile" />,
          },
        ],
      },
      { path: "register-member", element: <RegisterMemberForm /> },
      { path: "not-found", element: <NotFound /> },
      { path: "server-error", element: <ServerError /> },
      { path: "*", element: <Navigate replace to="/not-found" /> },
    ],
  },
];

export const router = createBrowserRouter(routes);
