import CommonStore from "./commonStore";
import MemberStore from "./memberStore";
import { createContext, useContext } from "react";
import UserStore from "./userStore";
import ModalStore from "./modalStore";
import PaymentStore from "./paymentStore";
import FamilyMemberStore from "./familyMemberStore";
import ActivityStore from "./activityStore";
import TenantStore from "./tenantStore";
import ParticipantStore from "./participantStore";
import DocumentStore from "./documentStore";
import StatisticStore from "./statisticStore";

interface Store {
    memberStore: MemberStore;
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    paymentStore: PaymentStore;
    familyMemberStore: FamilyMemberStore;
    activityStore: ActivityStore;
    tenantStore: TenantStore;
    participantStore: ParticipantStore;
    documentStore: DocumentStore;
    statisticStore: StatisticStore;
}

export const store: Store = {
    memberStore: new MemberStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    tenantStore: new TenantStore(),
    modalStore: new ModalStore(),
    paymentStore: new PaymentStore(),
    familyMemberStore: new FamilyMemberStore(),
    activityStore: new ActivityStore(),
    participantStore: new ParticipantStore(),
    documentStore: new DocumentStore(),
    statisticStore: new StatisticStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}