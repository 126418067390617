import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Header, Segment, Image } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import LoginForm from "../users/LoginForm";
import { UserRole } from "../../app/common/enums/userRole";
import { useTranslation } from "react-i18next";

export default observer(function WelcomePage() {
  const { userStore, modalStore } = useStore();
  const { t } = useTranslation();

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text>
        <Header as="h1" inverted>
          <Image
            size="massive"
            src="/assets/logo.png"
            alt="logo"
            style={{ marginBottom: 12 }}
          />
          Džemat Köln
        </Header>
        {userStore.isLoggedIn ? (
          <>
            <Header
              as="h2"
              inverted
              content={`${t("welcomePage.welcomeBack")} ${
                userStore.loggedUser?.username
              }`}
            />
            <Button
              as={Link}
              to={
                userStore.userRole === UserRole.SUPER_ADMIN
                  ? "/users-create"
                  : "/home"
              }
              size="huge"
              inverted
            >
              {t("welcomePage.enterApp")}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => modalStore.openModal(<LoginForm />)}
              size="huge"
              inverted
            >
              {t("welcomePage.login")}
            </Button>
          </>
        )}
      </Container>
    </Segment>
  );
});
