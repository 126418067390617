import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useStore } from "../../app/stores/store";
import LoadingComponent from "../../app/layout/LoadingComponent";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import CustomTextArea from "../../app/common/form/CustomTextArea";
import { ActivityFormValues } from "../../app/models/activity";
import TabContainer, { TabData } from "../../app/common/TabContainer";
import ParticipantsTable from "../participants/ParticipantsTable";
import CustomDateInput from "../../app/common/form/CustomDateInput";
import { useTranslation } from "react-i18next";

export default observer(function ActivityForm() {
  const { t } = useTranslation();
  const [activity, setActivity] = useState<ActivityFormValues>(
    new ActivityFormValues()
  );
  const { activityStore, userStore } = useStore();
  const { createActivity, updateActivity, loadActivity, loadingInitial } =
    activityStore;
  const navigate = useNavigate();
  const { id } = useParams();
  const commonRequiredMessage = t("common.requiredMessage");
  const validationSchema = Yup.object({
    name: Yup.string().required(
      `${t("activityForm.nameLabel")} ${commonRequiredMessage}`
    ),
  });

  useEffect(() => {
    if (id)
      loadActivity(id).then((activity: any) =>
        setActivity(new ActivityFormValues(activity))
      );
  }, [id, loadActivity]);

  const handleFormSubmit = (activity: ActivityFormValues) => {
    if (!activity.id) {
      const newActivity = {
        ...activity,
        id: uuid(),
        userId: userStore.loggedUser?.id!,
      };
      createActivity(newActivity).then(() => navigate(`/activities`));
    } else {
      updateActivity(activity).then(() => navigate(`/activities`));
    }
  };

  const tabs: TabData[] = [
    {
      menuItem: t("activityForm.participants"),
      render: () => <ParticipantsTable />,
    },
  ];

  if (loadingInitial) return <LoadingComponent content={t("common.loading")} />;

  return (
    <>
      <Header
        as="h2"
        content={
          !activity.id
            ? t("activityForm.addActivityHeader")
            : `${activity.name} - ${t("activityForm.editActivityHeader")}`
        }
      />
      <Segment clearing>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            ...activity,
            createdByUser: activity.createdByUser?.username as any,
          }}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form
              className="ui form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Header
                content={t("general.basicData")}
                sub
                color="teal"
              />
              <div className={activity.id ? "two fields" : "one field"}>
                <CustomTextInput
                  name="name"
                  placeholder={t("activityForm.namePlaceholder")}
                  label={t("activityForm.nameLabel")}
                />

                {activity.id && (
                  <CustomDateInput
                    name="createdAt"
                    value={activity.createdAt!}
                    label={t("activityForm.createdAtLabel")}
                    placeholderText={t("activityForm.createdAtLabel")}
                    dateFormat="dd.MM.yyyy"
                  />
                )}
              </div>

              <CustomTextArea
                rows={3}
                name="note"
                placeholder={t("activityForm.notePlaceholder")}
                label={t("activityForm.noteLabel")}
              />

              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated="right"
                positive
                type="submit"
                content={t("general.confirm")}
              />
              <Button
                as={Link}
                to="/activities"
                floated="right"
                type="button"
                content={t("general.cancel")}
              />
            </Form>
          )}
        </Formik>
      </Segment>

      {id && (
        <Segment>
          <TabContainer tabs={tabs} />
        </Segment>
      )}
    </>
  );
});
