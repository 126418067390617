import { MemberStatus } from "../common/enums/memberStatus";
import { PaymentMethod } from "../common/enums/paymentType";
import { SalutationType } from "../common/enums/salutationType";
import { SubscriptionType } from "../common/enums/subscriptionType";
import { BasePerson } from "./person";

export class MemberFormValues {
  id?: string = undefined;
  salutation = SalutationType.Mr;
  firstName = "";
  lastName = "";
  dateOfBirth = null;
  placeOfBirth = "";
  city = "";
  country = "";
  street = "";
  zipCode = "";
  email = "";
  phone = "";
  mobilePhone = "";
  note = "";
  status = MemberStatus.Active;
  joinedAt = null;
  memberNumber = "";
  profession = "";
  paymentMethod = PaymentMethod.Account;
  bankName = "";
  iban = "";
  bic = "";
  subscriptionType = SubscriptionType.Monthly;
  memberShipFee = 240;

  constructor(member?: MemberFormValues) {
    if (member) {
      Object.assign(this, member);
    }
  }
}

export class Member extends BasePerson {
  constructor(init?: MemberFormValues) {
    super();
    Object.assign(this, init);
  }
}
