import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import { Button, Segment } from "semantic-ui-react";
import CustomTextArea from "../../app/common/form/CustomTextArea";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useStore } from "../../app/stores/store";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import {
  FamilyMember,
  FamilyMemberFormValues,
} from "../../app/models/familyMember";
import CustomDateInput from "../../app/common/form/CustomDateInput";
import { runInAction } from "mobx";
import CustomCheckbox from "../../app/common/form/CustomCheckBox";
import CustomSelectInput from "../../app/common/form/CustomSelectInput";
import { useRelationShipTypeOptions } from "../../app/common/enums/relationType";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string;
}

export default observer(function FamilyMemberForm({ id }: Props) {
  const { t } = useTranslation();
  const [familyMember, setFamilyMember] = useState<FamilyMemberFormValues>(
    new FamilyMemberFormValues()
  );
  const { familyMemberStore, modalStore, memberStore } = useStore();
  const {
    createFamilyMember,
    updateFamilyMember: updatePayment,
    loading,
  } = familyMemberStore;
  const relationShipTypeOptions = useRelationShipTypeOptions();
  const commonRequiredMessage = t("familyMemberForm.requiredMessage");
  const validationSchema = Yup.object({
    firstName: Yup.string().required(
      `${t("familyMemberForm.firstNameLabel")} ${commonRequiredMessage}`
    ),
    lastName: Yup.string().required(
      `${t("familyMemberForm.lastNameLabel")} ${commonRequiredMessage}`
    ),
  });
  const handleFormSubmit = (familyMember: FamilyMemberFormValues) => {
    if (!familyMember.id) {
      const newFamilyMember: FamilyMember = {
        ...familyMember,
        id: uuid(),
        memberId: memberStore.selectedMember?.id!,
      };
      createFamilyMember(newFamilyMember as any).then(modalStore.closeModal);
    } else {
      familyMember.id = id;
      updatePayment({ ...familyMember }).then(() => {
        runInAction(() => {
          familyMemberStore.tableData.list.set(id!, { ...familyMember });
        });
        modalStore.closeModal();
      });
    }
  };

  useEffect(() => {
    id &&
      familyMemberStore
        .loadFamilyMember(id)
        .then((familyMember: any) =>
          setFamilyMember(new FamilyMemberFormValues(familyMember))
        );
  }, [id]);

  if (loading)
    return <LoadingComponent content={t("familyMemberForm.loading")} />;

  return (
    <Segment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          ...familyMember,
          ...((!familyMember.id && {
            lastName: memberStore.selectedMember?.lastName || "",
          }) as any),
        }}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form
            className="ui form"
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{ paddingBottom: "35px" }}
          >
            <div className="two fields">
              <CustomTextInput
                type="text"
                name="firstName"
                placeholder={t("familyMemberForm.firstNamePlaceholder")}
                label={t("familyMemberForm.firstNameLabel")}
              />
              <CustomTextInput
                type="text"
                name="lastName"
                placeholder={t("familyMemberForm.lastNamePlaceholder")}
                label={t("familyMemberForm.lastNameLabel")}
              />
            </div>

            <div className="three fields">
              <CustomDateInput
                name="dateOfBirth"
                label={t("familyMemberForm.dateOfBirthLabel")}
                placeholderText={t("familyMemberForm.dateOfBirthLabel")}
                dateFormat="dd.MM.yyyy"
              />
              <CustomSelectInput
                options={relationShipTypeOptions}
                name="relationShipType"
                placeholder={t("familyMemberForm.relationShipTypeLabel")}
                label={t("familyMemberForm.relationShipTypeLabel")}
              />
              <CustomCheckbox
                name="mekteb"
                label={t("familyMemberForm.mektebLabel")}
              />
            </div>

            <CustomTextArea
              label={t("familyMemberForm.notesLabel")}
              rows={3}
              name="note"
              placeholder={t("familyMemberForm.notesLabel")}
            />

            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content={t("general.confirm")}
            />
            <Button
              onClick={() => {
                modalStore.closeModal();
              }}
              floated="right"
              type="button"
              content={t("general.cancel")}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
